/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
/* eslint-disable indent */
import React, { Component, useContext, Fragment } from "react";
import _ from "lodash";
import PropTypes, { object } from "prop-types";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { validateEmailList} from "../components/billing_screen/helpers";
import {
  Button,
  Col,
  Drawer,
  Icon,
  Row,
  Spin,
  Tooltip,
  Select,
  Modal,
  FormItem,
  Input,
  Result,
} from "../common/UIComponents";
import {
  alertMessage,
  formatDate,
  isEmpty,
  base64ToPdf,
} from "../common/Common";
import I18n from "../common/I18n";
import {
  fetchOrders,
  deleteOrder,
  changeOrderStatus,
  changeStatusOfOrders,
  changeReceivedToVerify,
  deleteMultipleOrder,
  fetchOrdersStatusByFilter,
  exportOrders,
} from "../api/OrdersApi";
import { fetchShortFormAccounts } from "../api/Account";
import { fetchLocations } from "../api/LocationsApi";
import BaseModal from "../components/BaseModal";
import OrderDetails from "../components/Tasks/OrderDetails";
import AppConfig from "../config/AppConfig";
import excelIcon from "../../assets/images/excel.png";
import dateIcon from "../../assets/images/weekly-schedule_outlined.png";
import SurveyResponse from "../components/surveys/SurveyResponse";
import userStore from "../stores/UserStore";
import EditOrderForm from "../components/orders/EditOrderForm";
import ChangeStatusForm from "../components/orders/ChangeStatusForm";
import CalendarContainer from "../components/orders/CalendarContainer";
import PreplanForm from "../components/preplan/Form";
import OrderFilter from "../components/orders/OrderFilter";
import Invoice from "../components/billing/Invoice";
import { saveInvoice } from "../api/Billing";
import OrderTitle from "../components/orders/OrderTitle";
import { getSortingKey } from "../helpers/orders";
import OrdersStats from "../components/orders/OrdersStats";
import AppliedFilters from "../components/orders/AppliedFilters";
import OrdersBulkOperations from "../components/orders/OrdersBulkOperations";
import { PrintModal } from "./PrintModal";
import BillOfRatingModal from "./BillOfRatingModal";
import { blobDownload, checkServiceExistance, dynamicFileDownload, renderAlertMessage, validateEmail } from "../helpers/common";
import { fetchBolPdf } from "../api/BillingScreenAPI";
import { fetchExceptionMessages } from "../api/PreplanApi";
import { DisplaySettingsContext } from "../context/DisplaySettings";
import { useState,useEffect } from "react";
import { WarehouseContext } from "../context/WarehouseContext";
import { OrgContext } from "../context/OrgContext";
import Dms from "./Dms";
import { UserContext } from "../context/UserContext";
import OrderSortByOptions from "../components/orders/OrdersSortByOptions";
import { RecordsPerPage } from "../components/orders/RecordsPerPage";
import { BarCodeModal } from './commonModals';
import OrdersList from "../components/Tasks/OrdersListRe";
import { VehicleTypeApi } from "../api/VehicleType";
import { OrderConfigContext } from "../context/OrderConfigContext";
import { ScreenKeys, perPageKeys } from "./constants";
import UnallocatedOrdersGridList from "../components/dispatches/UnallocatedOrdersGridList";
import { getFromToDate } from "../components/orders/helpers";
import { fetchFilteredUser } from "../api/UsersApi";
import MoreChangeStatusForm from "../components/orders/MoreChangeStatusForm";
import OrderBulkOperationContainer from "../components/orders/OrderBulkOperationsContainerComponent";


const deletionStatus = ["NEW", "RECEIVED", "ON_HOLD", "VERIFIED", "PREPLAN", "ASSIGNED"];

class OrdersComponent extends Component {
  // static contextType = DisplaySettingsContext;
  constructor(props) {
    super(props);
    const ordersToken =
      props.location && props.location.filterPlaceHolder
        ? props.location.filterPlaceHolder.search_order_token
        : "";
    const filter = !isEmpty(ordersToken)
      ? ""
      : !isEmpty(props.location.filter) || props.location.filter === ""
      ? props.location.filter
        : props.filterPlaceHolder ? props.filterPlaceHolder.filter : I18n.t("order.default_status");
    const filterPlaceHolder =
      props.location && props.location.filterPlaceHolder
        ? props.location.filterPlaceHolder
        : Object.assign({}, this.props.defaultFilterValues, {
            filter,
            search_order_token: ordersToken,
          });
    const fromOrderIndvScreen = props.location && props.location.isFromIndScreen;
    this.state = {
      orderInfo: [],
      currentOrder: {},
      exceptions: [],
      users : [],
      exception_selected_codes:
        fromOrderIndvScreen && props?.orderConfigContext.selectedException
          ? props.orderConfigContext.selectedException
          : [],
      showSurvey: false,
      surveyResponseId: "",
      orderObject: {},
      inProgress: false,
      isLoading: false,
      isUsingBetaVersion: false,
      status: {
        All: "",
        New: "NEW",
        Received: "RECEIVED",
        Verified: "VERIFIED",
        Preplan: "PREPLAN",
        Assigned: "ASSIGNED",
        Prepare: "PREPARE",
        Dispatched: "DISPATCHED",
        Completed: "COMPLETED",
        Exception: "EXCEPTION",
        Cancelled: "CANCELLED",
        Closed: "CLOSED",
        Archived: "ARCHIVED",
        Onhold: "ON_HOLD",
      },
      filter,
      filterPlaceHolder,
      detailsVisible: false,
      routes: {
        selectedOrderKeys: [],
        selectedOrderRows: [],
      },
      editForm: false,
      filterWindow: false,
      statsData: {},
      pagination: {},
      statusChangeModal: false,
      currentStatus: "",
      showCalenderView: false,
      showPreplan: false,
      currentInvoice: {},
      showInvoiceModel: false,
      accounts: [],
      currentAccount: {},
      currentWarehouse: {},
      showStatusErrors: false,
      warehouses: [],
      processOrders: [],
      hasBulkOrders: false,
      showBillOfRatingModal: false,
      displayConfiguration: {},
      organizationSettings: {},
      isMilitaryTime: false,
      isGeneratingPdf: false,
      // appliedFiltersTags: [],
      statsProgress: false,
      showBarCodeModal: false,
      recordsPerPage: props.recordsPerPage,
      resizableColumns: [],
      vehicleTypes: [],
      isModalVisible: false,
      billingEmails: [],
      currentGridRef: null,
      showRearrangeableModal: false,
      confirmBulkDelete: false
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    this.onTableChange = this.onTableChange.bind(this);
    // debounce
    this.getOrders = _.debounce(this.getOrders, 500);
    // this.getOrdersStatus = _.debounce(this.getOrdersStatus, 500);
    this.getAccounts = _.debounce(this.getAccounts, 500);
    // this.getWarehouses = _.debounce(this.getWarehouses, 500);
  }
  addBilligEmail = (value) => {
    const validEmails = validateEmailList(value);
    this.setState({ billingEmails: validEmails });
  };
  onChange = (value) => {
    this.setState(
      {
        exception_selected_codes: value,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getOrders();
      }
    );
  };

  getFilteredUsers = () => {
    fetchFilteredUser()
      .then((result) => {
        if (result.success) {
          this.setState({users: result.users || []});
        } else {
          renderAlertMessage(result.errors)
          this.setState({ users : [] });
      }
    });
  };

  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage
    }
    this.setState(
      {
        recordsPerPage: value,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getOrders();
        updateRecordsPerPage(ScreenKeys.ORDER_LISTING, value);
      }
    );
  };

  onSearch = (value) => {
  };

  toggleEmailModal = (value) => {
    this.setState({
      isModalVisible: value,
      billingEmails:[],
    })
  };
  componentDidMount() {
    const isUsingBetaVersion = this.props.location.pathname.includes('orders-beta');
    const { paginationData } = this.props.orderConfigContext;
    const propsLocationData = this.props.location;
    const fromOtherScreen = this.props?.history?.location?.calledFrom;
    const fromOrderIndvScreen = this.props?.history?.location?.isFromIndScreen;
    const isReloaded = this.props?.history?.action === "POP";
    // if page is reloaded fetch orders with saved filter
    if (isReloaded) {
      const { filterPlaceHolder: savedFilter, setFilterPlaceHolder: updateSavedFilter } = this.props.orderConfigContext;
      const { currentUser } = this.props.userContext;
      const { searchCriteria, selectedWarehouses } = this.props.warehouseFilter;
      const selectedwh = this.getSelectedWarehouse(selectedWarehouses);
      const { orders = {} } = searchCriteria;
      const {
        account_code,
        from_date,
        order_type,
        to_date,
        zone_ids,
        schedule_orders_type,
        schedule_day_filter,
        created_by_ids,
      } = orders;

      const delivery_zones =
        currentUser?.delivery_zones?.length > 0 ? currentUser.delivery_zones : [];
      const filteredZones = this.getFilteredZones(delivery_zones, selectedwh);
      const curentZones = this.getCurrentZones(filteredZones, zone_ids);
      const { fromDate, toDate } = getFromToDate(schedule_day_filter, to_date, from_date);
      const filter = this.getFilterObject(fromDate, toDate, "VERIFIED", account_code, savedFilter, schedule_orders_type, curentZones, order_type, created_by_ids);

      this.updateFilter(filter);
      updateSavedFilter(filter);
    }
    else if (isEmpty(fromOtherScreen) && isEmpty(fromOrderIndvScreen)) {
      // this.setCurrentWhSavedSearch();
      const { filterPlaceHolder : savedFilter } = this.props.orderConfigContext;
      this.updateFilter(savedFilter);
    }else{
      this.getOrders(
        propsLocationData.filter ? propsLocationData.filter : this.state.filter
      );
    }
    
    this.processQueryParams(this.props.location.search);
    if (
      !isEmpty(propsLocationData.showQuoteOrder) &&
      propsLocationData.showQuoteOrder === true
    ) {
      this.showQuoteOrder();
    }
    this.getAccounts();
    this.getExceptionMessages();
    this.getVehicleTypes();
    this.getFilteredUsers();
    // this.getWarehouses();

    // this.getOrdersStatus();
    // this.getUsers();
    // if(this.props.filterPlaceHolder.warehouse_id){
    //   this.handleWhsChange();
    // }
    if (this.props.warehouseFilter.selectedWarehouses.length > 0) {
      this.handleSearch();
    }
    this.setState({
      displayConfiguration: this.props.displayConfiguration,
      organizationSettings: this.props.organizationSettings,
      isUsingBetaVersion,
      isMilitaryTime:
        this.props.organizationSettings.is_military_time == "true",
    },()=>{
      // if (!isEmpty(fromOrderIndvScreen)) {
        this.tableOptions.pagination.current = paginationData.current_page;
      // }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.warehouseFilter.warehouseWithAccounts, this.props.warehouseFilter.warehouseWithAccounts)) {
      this.getAccounts();
    }
    if (
      !_.isEqual(
        prevProps.warehouseFilter.selectedWarehouses,
        this.props.warehouseFilter.selectedWarehouses
      ) ||
      (!_.isEqual(
        prevProps.warehouseFilter.warehouses,
        this.props.warehouseFilter.warehouses
      ) &&
        !this.props.warehouseFilter.isFetchingWarehouses)
    ) {
      // this.handleWhsChange();
      this.getAccounts(this.props.warehouseFilter.selectedWarehouses);
      this.tableOptions.pagination.current = 1;
      this.handleSearch();
    }
    if (
      !_.isEqual(
        prevState.displayConfiguration,
        this.props.displayConfiguration
      )
    )
      if (
        !_.isEqual(
          prevState.organizationSettings,
          this.props.organizationSettings
        )
      ) {
        this.setState(
          {
            displayConfiguration: this.props.displayConfiguration,
            organizationSettings: this.props.organizationSettings,
            isMilitaryTime:
              this.props.organizationSettings.is_military_time == "true",
          },
          () => {
            // Update OrdersList props with updated state values
            this.updateOrdersListProps();
          }
        );
      }

  }

  setCurrentWhSavedSearch = () => {
    const { filterPlaceHolder } = this.state;
    const filteredStatus  = this.state.filterPlaceHolder.filter;
    const { currentUser} = this.props.userContext;
    const {searchCriteria , selectedWarehouses } = this.props.warehouseFilter;
    const selectedwh =
      _.isArray(selectedWarehouses) && selectedWarehouses.length > 0
        ? selectedWarehouses[0]
        : selectedWarehouses;
    const { orders = {} } = searchCriteria;
    const {
      account_code,
      from_date,
      order_type,
      to_date,
      zone_ids,
      schedule_orders_type,
      name_search_key,
      phone_search_key,
      schedule_day_filter,
    } = orders;

    // filtering current warehouse zones then setting saved search Zones....
    const delivery_zones =
      currentUser?.delivery_zones?.length > 0 ? currentUser.delivery_zones : [];
    const filteredZones = delivery_zones
      .filter((zone) => zone.warehouse_id === selectedwh)
      .map((zone) => ({ label: zone.zone_name, value: zone.zone_id }));
    const zonesIds = zone_ids?.length > 0 ? zone_ids.split(",") : [];
    const curentZones = filteredZones?.filter((zone) =>
      zonesIds.includes(zone.value)
    );
    //..........

    const { filterPlaceHolder : savedFilter } = this.props.orderConfigContext;
    const { fromDate, toDate } = getFromToDate(schedule_day_filter, to_date , from_date);
    const filter = Object.assign({}, this.state.filterPlaceHolder, {
      fromDate,
      toDate,
      filter: filteredStatus,
      search_order_token: filterPlaceHolder?.search_order_token ? filterPlaceHolder.search_order_token : "",
      sortBy: "none",
      sortByType: "descend",
      account_codes: !isEmpty(account_code) ? account_code.split(",") : [],
      warehouse_id: "",
      search_type : _.isEmpty(savedFilter.search_type) ? "EQUALS" : savedFilter.search_type,
      search_key_type : _.isEqual(savedFilter.search_type, "CONTAINS") && !_.isEmpty(savedFilter.search_key_type) ? savedFilter.search_key_type : "",
      [I18n.t("order.filters.schedule_orders.type")]: !isEmpty(
        schedule_orders_type
      )
        ? schedule_orders_type
        : I18n.t("order.filters.schedule_orders.scheduled_key"),
      zone_ids: curentZones?.length > 0 ? curentZones : [],
      order_type: !isEmpty(order_type) ? order_type : "",
      schedule_day_filter
    });
    this.updateFilter(filter);
  };

  updateOrdersListProps = () => {
    this.setState({
      ordersListProps: {
        displayConfiguration: this.state.displayConfiguration,
      },
    });
  };

  createOrderFromTemplate = (templateId) => {
    // open create order form with template data
    this.setState(
      {
        orderObject: {
          template_id: templateId,
        },
      },
      () => {
        this.setState({ editForm: true, isNew: true });
      }
    );
  };

  processQueryParams = (search) => {
    // pathname: '/orders?operation=template_create&template_id=' + templateId,
    const params = new URLSearchParams(search);
    const operation = params.get("operation");
    const templateId = params.get("template_id");
    if (operation === "template_create") {
      this.createOrderFromTemplate(templateId);
    }
  };


  getVehicleTypes = () => {
    VehicleTypeApi.fetch().then((result) => {
      if (result.success) {
        this.setState({
			vehicleTypes: result.vehicle_types,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ vehicleTypes: [], inProgress: false });
      }
    });
  };


  getExceptionMessages = () => {
    fetchExceptionMessages().then((result) => {
      if (result.success) {
        const exceptions = [...result.exceptions];
        this.setState({
          exceptions,
        });
      } else {
        this.setState({ exceptions: [] });
      }
    });
  };

  showQuoteOrder = () => {
    const { orderObject } = this.props.location;
    if (!isEmpty(orderObject)) {
      this.setState(
        {
          orderObject,
        },
        () => {
          this.setState({ editForm: true, isNew: false });
        }
      );
    }
  };

  // handleWhsChange = () =>{
  //     this.setState({
  //       filterPlaceHolder: {
  //         ...this.state.filterPlaceHolder,
  //         warehouse_id: this.props.filterPlaceHolder.warehouse_id,
  //       },
  //     } , () => {
  //       this.setState({inProgress : true});
  //       this.handleSearch();
  //       this.setState({inProgress : false});
  //     })
  // }

  getAccounts = (whId) => {
    const accountsData = this.props.warehouseFilter.findMatchingAccounts(whId);
    this.setState({
      accounts: accountsData,
    }),
      () => {
        if (this.state.filterPlaceHolder.warehouse_id) {
          let currentWarehouse = {};
          const whIndex = _.findIndex(this.state.warehouses, {
            id: this.state.filterPlaceHolder.warehouse_id,
          });
          if (whIndex >= 0) {
            currentWarehouse = this.state.warehouses[whIndex];
          }
          this.setState({
            currentWarehouse,
          });
        }
      };
  };

  // getWarehouses = () => {
  //   fetchLocations(AppConfig.warehouseCode, 1, null).then((result) => {
  //     if (result.success) {
  //       this.setState(
  //         {
  //           warehouses: result.locations.locations,
  //         },
  //         () => {
  //           if (this.state.filterPlaceHolder.warehouse_id) {
  //             let currentWarehouse = {};
  //             const whIndex = _.findIndex(this.state.warehouses, {
  //               id: this.state.filterPlaceHolder.warehouse_id,
  //             });
  //             if (whIndex >= 0) {
  //               currentWarehouse = this.state.warehouses[whIndex];
  //             }
  //             this.setState({
  //               currentWarehouse,
  //             });
  //           }
  //         }
  //       );
  //     }
  //   });
  // };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.filterPlaceHolder &&
      !isEmpty(nextProps.searchType) &&
      (nextProps.searchType !== this.props.searchType ||
        !_.isEqual(nextProps.filterPlaceHolder, this.props.filterPlaceHolder))
    ) {
      // && !_.isEqual(nextProps.filterPlaceHolder, this.props.filterPlaceHolder
      if (!isEmpty(nextProps.filterPlaceHolder)) {
        const filterPlaceHolder = nextProps.filterPlaceHolder
          ? nextProps.filterPlaceHolder
          : this.props.defaultFilterValues;
        const filter =
          nextProps.searchType === "byOrders"
            ? filterPlaceHolder.search_order_token === ""
              ? I18n.t("order.default_status")
              : ""
            : this.state.filter;
        this.setState(
          {
            filterPlaceHolder: Object.assign({}, filterPlaceHolder, {
              filter,
            }),
            filter,
          },
          () => {
            if (nextProps.searchType === "byOrders") {
              this.handleOrderNumberSearch();
            } else if (nextProps.searchType === "byFilter") {
              this.handleSearch();
            }
          }
        );
      }
    }
  }

  onOk = (value) => {
    this.getOrders();
  };

  onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getOrders();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.tableOptions.pagination.current = 1;
      this.handleOnFilterElementChange("sortBy", {
        sortKey: sorter.columnKey,
        sortKeyType: !isEmpty(sorter.order) ? sorter.order : "ascend",
      });
    }
  };

  // getOrdersStatus = () => {
  //   const { filterPlaceHolder, exception_selected_codes, filter } = this.state;
  //   this.setState({ statsProgress: true });
  //   const order_number = !isEmpty(filterPlaceHolder.search_order_token)
  //     ? filterPlaceHolder.search_order_token.toUpperCase()
  //     : "";
  //   const formattedFromDate = formatDate(filterPlaceHolder.fromDate);
  //   const formattedToDate = formatDate(filterPlaceHolder.toDate);
  //   const searchKeyType = filterPlaceHolder.search_key_type;
  //   const searchKeyValue = filterPlaceHolder.search_key_value;
  //   const scheduleOrdersType = filterPlaceHolder.schedule_orders_type;
  //   const zoneIds = filterPlaceHolder.zone_ids || [];
  //   const exception_code = exception_selected_codes;
  //   const status = filter === "ALL" ? "" : filter;
  //   const order_type= filterPlaceHolder.order_type;
  //   const vehicle_type = filterPlaceHolder.vehicle_type;
  //   fetchOrdersStatusByFilter(
  //     formattedFromDate,
  //     formattedToDate,
  //     order_number,
  //     filterPlaceHolder.account_codes,
  //     filterPlaceHolder.warehouse_id,
  //     searchKeyType,
  //     searchKeyValue,
  //     scheduleOrdersType,
  //     true,
  //     zoneIds,
  //     status,
  //     exception_code,
  //     order_type,
  //     vehicle_type
  //   ).then((result) => {
  //     if (result.success) {
  //       this.setState({
  //         statsProgress: false,
  //         statsData:
  //           result.data && result.data.length > 0 ? result.data[0] : {},
  //       });
  //     } else {
  //       this.setState({
  //         statsProgress: false,
  //       });
  //       renderAlertMessage(result.errors)
  //     }
  //   });
  // };

  navigateToRoute = (routeId, routeStatus, date) => {
    // this.props.history.push(`/stops/${routeId}`);
    if (routeStatus !== "ALLOCATED") {
      this.props.history.push({
        pathname: "/routes",
        filter: routeStatus,
        routeId,
        dateFilter: {
          fromDate: date,
          toDate: date,
        },
      });
    }
  };

  navigateToAccount = (account_code) => {
    const accountIndex = _.findIndex(this.state.accounts, [
      "code",
      account_code,
    ]);
    if (accountIndex >= 0) {
      const accountData = Object.assign({}, this.state.accounts[accountIndex]);
      const account_id = !isEmpty(accountData.primary_account_id)
        ? accountData.primary_account_id
        : accountData.id;
      this.props.history.push(`/accounts/${account_id}/settings`);
    }
  };

  gotoOrderDetails = (id, isDetailScreen = true) => {
    const { filter, pagination, recordsPerPage, exception_selected_codes } = this.state;
    const { setPaginationData, setRecordPerPage, setSelectedException, setFilterPlaceHolder } = this.props.orderConfigContext;

    setFilterPlaceHolder((prevState) => ({ ...prevState, filter }));
    setPaginationData(pagination);
    setRecordPerPage(recordsPerPage);
    setSelectedException(exception_selected_codes);

    const path = isDetailScreen ? `/orders/${id}/details` : `/orders/${id}/edit`;
    this.props.history.push(path);
  };


  gotoCreateOrder = () => {
    this.props.history.push(`/orders/create`);
  };

  getOrders = (initial, cb = null) => {
    const { filterPlaceHolder, filter, routes, exception_selected_codes } = this.state;
    this.setState({ isLoading: true , statsProgress: true });

    const page = this.tableOptions.pagination.current;
    const perPage = this.state.isUsingBetaVersion ? 1000 : initial ? initial.perPage : this.state.recordsPerPage; // removed AppConfig.ordersPerPage to dynamically adjust record length 
    const orderNumber = !isEmpty(filterPlaceHolder.search_order_token)
      ? filterPlaceHolder.search_order_token.toUpperCase()
      : "";
    const formattedFromDate = formatDate(filterPlaceHolder.fromDate);
    const formattedToDate = formatDate(filterPlaceHolder.toDate);
    const searchKeyType = filterPlaceHolder.search_key_type;
    const searchKeyValue = filterPlaceHolder.search_key_value;
    const scheduleOrdersType = filterPlaceHolder.schedule_orders_type;
    const scheduleDayFilter = filterPlaceHolder.schedule_day_filter;
    // const isSaveNsearchCalled = this.props.location.saveNSearchCall || false;
    // this.getOrdersStatus();
    fetchOrders({
      search_type: filterPlaceHolder.search_type,
      status: filter === "ALL" ? "" : filter,
      fromDate: formattedFromDate,
      todate: formattedToDate,
      scheduleDayFilter,
      scheduleOrdersType,
      page,
      perPage,
      orderNumber,
      sortBy: filterPlaceHolder.sortBy,
      sortByType: filterPlaceHolder.sortByType || "ascend",
      account_codes: filterPlaceHolder.account_codes,
      warehouseId: filterPlaceHolder.warehouse_id,
      searchKeyType,
      searchKeyValue,
      scheduleOrdersType,
      globalSearch: true,
      zone_ids: filterPlaceHolder.zone_ids,
      order_type: filterPlaceHolder.order_type,
      exception_code: exception_selected_codes,
      saveNSearch: true,
      vehicle_type: filterPlaceHolder.vehicle_type,
      created_by : filterPlaceHolder.created_by,
    }).then((result) => {
      const key_mapping = {
        "new_orders": "NEW",
        "received_orders": "RECEIVED",
        "verified_orders": "VERIFIED",
        "assigned_orders": "ASSIGNED",
        "dispatched_orders": "DISPATCHED",
        "completed_orders": "COMPLETED",
        "closed_orders": "CLOSED",
        "exception_orders": "EXCEPTION",
        "total_orders_count": "ALL",
        "on_hold": "ON_HOLD",
      };

      // mapping order status count to statsData
      const statsData = {};
      Object.keys(key_mapping).forEach((key) => {
        const value = _.get(result.stats, key_mapping[ key ], 0);
        statsData[ key ] = value;
      });

      // if (result.success) {
      //   // const filter = this.state.filter;
      //   const total_orders = result.orders.customer_orders ? result.orders.customer_orders : [];
      //   const searchedHistoryData = result.orders?.search_history ? result.orders.search_history : [];
      //   const orderIds = [];
      //   const orders = total_orders.map((x) => {
      //     orderIds.push(x.order.id);
      //     return Object.assign({}, x.order, {
      //       driver_name: x.driver_name,
      //       appointments: x.order_appointments || [],
      //       nav_route_id: x.nav_route_id,
      //       nav_route_name: x.nav_route_name,
      //       nav_route_status: x.nav_route_status,
      //       scheduled_start_datetime_with_tz:
      //         x.scheduled_start_datetime_with_tz,
      //       scheduled_end_datetime_with_tz: x.scheduled_end_datetime_with_tz,
      //       timeZoneName: x.timeZoneName,
      //       tz_short_form: x.tz_short_form,
      //       company_name:
      //         x.cs_location && !isEmpty(x.cs_location.company_name)
      //           ? x.cs_location.company_name
      //           : "",
      //       customer_address:
      //         x.cs_location && x.cs_location.l_address
      //           ? {
      //               ...x.cs_location.l_address,
      //               location_code: x.cs_location.l_address.city,
      //             }
      //           : {},
      //       warehouse_address:
      //         x.wh_location && x.wh_location.l_address
      //           ? {
      //               ...x.wh_location.l_address,
      //               location_code: x.wh_location.location_code,
      //             }
      //           : {},
      //       zoneName:
      //         !isEmpty(x.order.order_zone_name) &&
      //         (_.isArray(x.order.order_zone_name)
      //           ? x.order.order_zone_name.join(", ")
      //           : ""),
      //       startTime: getSortingKey(x.order_appointments),
      //       wh_timezone: x.wh_location?.timeZoneId ? x.wh_location.timeZoneId : "EST",
      //       // origin: x.origin && !isEmpty(x.origin.l_address) ? x.origin.l_address : {},
      //       // destination: x.destination && !isEmpty(x.destination.l_address) ? x.destination.l_address : {},
      //       origin : x.origin && !isEmpty(x.origin.l_address) ? {
      //         ...x.origin.l_address,
      //         status: x.origin.status,
      //         company_name: x.origin.company_name,
      //         level_of_service: x.origin.level_of_service ? x.origin.level_of_service : "None",
      //         location_id: x.origin.id,
      //         service_duration: x.origin.service_duration
      //       } : {},
      //       destination : x.destination && !isEmpty(x.destination.l_address) ? {
      //         ...x.destination.l_address,
      //         status: x.destination.status,
      //         company_name: x.destination.company_name,
      //         level_of_service: x.destination.level_of_service ? x.destination.level_of_service : "None",
      //         location_id: x.destination.id,
      //         service_duration: x.destination.service_duration
      //       } : {},
      //     });
      //   });
      //   // checking any orders are selected
      //   const selectedOrderKeys = routes?.selectedOrderKeys
      //     ? routes.selectedOrderKeys.filter((id) => orderIds.includes(id))
      //     : [];
      //   const selectedOrderRows = routes?.selectedOrderRows
      //     ? routes.selectedOrderRows.filter((order) =>
      //         orderIds.includes(order.id)
      //       )
      //     : [];
      //   this.handleSelectChange(selectedOrderKeys, selectedOrderRows);

      //   this.setState(
      //     {
      //       orderInfo: orders,
      //       isLoading: false,
      //       pagination: orders.length > 0 ? result.pagination : {},
      //       statsProgress: false,
      //       statsData,
      //       filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
      //         filter,
      //       }),
      //       filter,
      //     },
      //     () => {
      //       this.setCurrentSearchHistory(searchedHistoryData);
      //       if (cb) {
      //         cb();
      //       }
      //     }
      //   );
      // } else {

      if (result.success) {
        const total_orders = _.get(result, 'orders.customer_orders', []);
        // const searchedHistoryData = _.get(result, 'orders.search_history', []);
        // get it from warehouse context
        const searchedHistoryData = _.get(this.props.warehouseFilter, 'searchCriteria.orders', {});
        
        const orderIds = [];
        const orders = _.map(total_orders, (x) => {
          orderIds.push(_.get(x, 'order.id'));
          return _.merge({}, _.get(x, 'order'), {
            driver_name: x.driver_name,
            appointments: _.sortBy((_.get(x, 'order_appointments', []) || []), "start_datetime"),
            //appointments: _.get(x, 'order_appointments', []) || [],
            driver: x.driver_name,
            nav_route_id: x.nav_route_id,
            nav_route_name: x.nav_route_name,
            nav_route_status: x.nav_route_status,
            scheduled_start_datetime_with_tz: x.scheduled_start_datetime_with_tz,
            scheduled_end_datetime_with_tz: x.scheduled_end_datetime_with_tz,
            timeZoneName: x.timeZoneName,
            tz_short_form: x.tz_short_form,
            company_name: _.get(x, 'cs_location.company_name', ""),
            customer_address: _.merge({}, _.get(x, 'cs_location.l_address', {}), {
              location_code: _.get(x, 'cs_location.l_address.city', ""),
            }),
            warehouse_address: _.merge({}, _.get(x, 'wh_location.l_address', {}), {
              location_code: _.get(x, 'wh_location.location_code', ""),
            }),
            zoneName: _.isArray(_.get(x, 'order.order_zone_name')) ? _.join(_.get(x, 'order.order_zone_name'), ", ") : "",
            startTime: getSortingKey(_.get(x, 'order_appointments')),
            wh_timezone: _.get(x, 'wh_location.timeZoneId', "EST"),
            origin: _.merge({}, _.get(x, 'origin.l_address', {}), {
              status: _.get(x, 'origin.status'),
              company_name: _.get(x, 'origin.company_name'),
              level_of_service: _.get(x, 'origin.level_of_service', "None"),
              location_id: _.get(x, 'origin.id'),
              l_type : _.get(x, 'origin.l_type'),
              service_duration: _.get(x, 'origin.service_duration'),
              driver_name: _.get(x, 'origin.driver_name'),
              location_partial_match:  _.get(x, 'origin.location_partial_match', false) || false,
              // bol_picture: _.get(x, 'origin.bol_pic[0]', ""),
            }),
            destination: _.merge({}, _.get(x, 'destination.l_address', {}), {
              status: _.get(x, 'destination.status'),
              company_name: _.get(x, 'destination.company_name'),
              level_of_service: _.get(x, 'destination.level_of_service', "None"),
              location_id: _.get(x, 'destination.id'),
              l_type : _.get(x, 'destination.l_type'),
              service_duration: _.get(x, 'destination.service_duration'),
              driver_name: _.get(x, 'destination.driver_name'),
              location_partial_match:  _.get(x, 'destination.location_partial_match', false) || false,
              // bol_picture: _.get(x, 'destination.bol_pic[0]', ""),
            }),
            bol_picture: _.get(x, 'bol', ""),
            delivery_bol_picture: _.get(x, 'del_bol', ""),
          });
        });
        console.log('this is to get bol', orders);

        const selectedOrderKeys = _.filter(_.get(routes, 'selectedOrderKeys'), (id) => _.includes(orderIds, id));
        const selectedOrderRows = _.filter(_.get(routes, 'selectedOrderRows'), (order) => _.includes(orderIds, _.get(order, 'id')));

        this.handleSelectChange(selectedOrderKeys, selectedOrderRows);

        this.setState({
          orderInfo: orders,
          isLoading: false,
          pagination: _.isEmpty(orders) ? {} : _.get(result, 'pagination'),
          statsProgress: false,
          statsData,
          filterPlaceHolder: _.merge({}, this.state.filterPlaceHolder, {
            filter,
          }),
          filter,
        }, () => {
          // this.setCurrentSearchHistory(searchedHistoryData);
          if (cb) {
            cb();
          }
        });
      }else{
        // alertMessage(result.errors[0], 'error', 5);
        this.handleSelectChange([], []);
        this.setState({
          orderInfo: [],
          isLoading: false,
          pagination: {},
          statsProgress: false,
          statsData
        });
      }
    });
  };

  // setCurrentSearchHistory = (searchedHistoryData) =>{
  //   const { selectedWarehouses } = this.props.warehouseFilter;
  //   const { setSearchCriteria, setCurrentUser, currentUser } = this.props.userContext;
  //   const selectedwh = _.isArray(selectedWarehouses) && selectedWarehouses.length > 0
  //     ? selectedWarehouses[0]
  //     : selectedWarehouses;

  //   const activeWhSearchHistory = searchedHistoryData.filter(
  //     (obj) => obj.warehouse_ids === selectedwh
  //   );
  //   const activeWhLatestSearch =
  //     activeWhSearchHistory[activeWhSearchHistory.length - 1];

  //   const searchHistory = {};
  //   searchHistory.orders = activeWhLatestSearch;
  //   setSearchCriteria(searchHistory);
  //   //.....Below to update latest save&searched data to currentUser search_history ....//
  //   const updatedCurrentUser = {
  //     ...currentUser,
  //     search_history: currentUser.search_history.map((item) => {
  //       if (item.search_page === "orders") {
  //         return {
  //           ...item,
  //           search_keys: [...item.search_keys, activeWhLatestSearch],
  //         };
  //       }
  //       return item;
  //     }),
  //   };

  //   setCurrentUser({ ...updatedCurrentUser });

  // }

  handleFilterPlaceHolderChange = (filter, cb = null) => {
    this.setState(
      {
        filterPlaceHolder: filter,
      },
      cb
    );
  };

  handleCurrentOrderChange = (element, value) => {
    this.setState({
      currentOrder: Object.assign({}, this.state.currentOrder, {
        [element]: value,
      }),
    });
  };

  handleSaveSuccess = (message, reflectDetails = false, id = "") => {
    alertMessage(message);
    if (!reflectDetails) {
      this.setState({ editForm: false }, () => {
        this.getOrders();
      });
    } else {
      this.handleEditOrderClick(id);
    }
  };

  handleOnFilterChange = (element, value) => {
    if (value === "ALL") {
      value = "";
    }

    if(element==="filter" && value === "EXCEPTION"){
      this.setState(
        {
          [element]: value,
          filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
            [element]: value,
          }),
          exception_selected_codes:[],

          routes: Object.assign({}, this.state.routes, {
            selectedOrderRows: [],
            selectedOrderKeys: [],
          }),
        },
        () => {
          this.tableOptions.pagination.current = 1;
          this.getOrders();
        }
      );
    }

    else{
      this.setState(
        {
          [element]: value,
          filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
            [element]: value,
          }),
          exception_selected_codes:[],

          routes: Object.assign({}, this.state.routes, {
            selectedOrderRows: [],
            selectedOrderKeys: [],
          }),
        },
        () => {
          this.tableOptions.pagination.current = 1;
          this.getOrders();
        }
      );
    }

  };

  //--------------------Above is old code-------------------------------------

  // handleOnFilterChange = (element, value) => {
  //   if (value === "ALL") {
  //     value = "";
  //   }
  //   const data = {
  //     [element]: value,
  //     filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
  //       [element]: value,
  //     }),
  //     exception_selected_codes: [],

  //     routes: Object.assign({}, this.state.routes, {
  //       selectedOrderRows: [],
  //       selectedOrderKeys: [],
  //     }),
  //   };
  //   if (element === "filter" && value === "EXCEPTION") {
  //     data.filterPlaceHolder.schedule_orders_type = "BOTH";
  //   }
  //   this.setState(data, () => {
  //     this.updateFilter(data.filterPlaceHolder);
  //     this.tableOptions.pagination.current = 1;
  //     this.getOrders();
  //   });
  // };

  handleOnOrderTokenClose = (orderNo) => {
    const filterVals = this.props.defaultFilterValues;
    const orderToken = this.state.filterPlaceHolder.search_order_token;
    if (!isEmpty(orderToken)) {
      const searchOrderToken = orderToken.replaceAll(" ", "");
      let tokenArray = _.uniq(_.split(searchOrderToken, ","));
      const trimmedToken = _.trim(orderNo);
      const orderIndex = _.indexOf(tokenArray, trimmedToken);
      if (orderIndex >= 0) {
        const otherTokens = tokenArray.filter(
          (token) => token !== trimmedToken
        );
        //tokenArray.splice(orderIndex, 1);
        const newOrderToken = _.join(otherTokens, ",");
        const prevSearchType = Object.keys(this.state.filterPlaceHolder).includes("search_type") ? this.state.filterPlaceHolder.search_type : "EQUALS";
        const prevSearchKey = prevSearchType !== "EQUALS" && Object.keys(this.state.filterPlaceHolder).includes("search_key_type") ? this.state.filterPlaceHolder.search_key_type : "";
        const filter = Object.assign({}, filterVals, {
          search_order_token: newOrderToken,
          search_key_value : newOrderToken,
          search_type : prevSearchType,
          search_key_type : prevSearchKey
        });
        
        if (otherTokens.length) {
          this.updateFilter(filter);
        }
        else{
         // this.clearFilter();
          const updatedFilter = {
            ...this.state.filterPlaceHolder, search_order_token: '', search_key_value: ''
          }
          const { setFilterPlaceHolder : updateGlobalFilter } = this.props.orderConfigContext;
          this.setState({
            filterPlaceHolder: updatedFilter,
          },() => {
            this.setCurrentWhSavedSearch()
            // updateGlobalFilter(updatedFilter)
          })
          //this.setCurrentWhSavedSearch()
        }
      }
    }
  };

  handleOrderDetailsViewClick = (id) => {
    const index = _.findIndex(this.state.orderInfo, ["id", id]);
    const orderObject = Object.assign({}, this.state.orderInfo[index]);
    this.setState(
      {
        // result.orders.customer_order
        orderObject,
      },
      () => {
        this.setState({ detailsVisible: true });
      }
    );
  };

  handleEditOrderClick = (id) => {
    const index = _.findIndex(this.state.orderInfo, ["id", id]);
    // const orderObject = Object.assign({}, this.state.orderInfo[index]);
    // this.setState(
    //   {
    //     orderObject,
    //   },
    //   () => {
    //     this.setState({ editForm: true, isNew: false });
    //     this.gotoOrderDetails(id, false);
    //   }
    // );

    // if from template 
    if(index === -1){
      this.gotoOrderDetails(id, false);
    }else{
      const orderObject = Object.assign({}, this.state.orderInfo[index]);
      this.setState(
        {
          orderObject,
        },
        () => {
          this.setState({ editForm: true, isNew: false });
          this.gotoOrderDetails(id, false);
        }
      );
    }

  };

  handleAddOrder = () => {
    this.setState(
      {
        currentOrder: {},
        orderObject: {},
      },
      () => {
        this.gotoCreateOrder();
        this.setState({ editForm: true, isNew: true });
      }
    );
  };

  handleExceptionOrder = (id) => {
    this.setState({
      inProgress: true,
    });
    changeOrderStatus(id).then((result) => {
      if (result.success) {
        alertMessage("Moved Successfully");
        this.setState(
          {
            inProgress: false,
          },
          () => {
            this.getOrders();
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({
          inProgress: false,
        });
      }
    });
  };

  showDrawer = () => {
    this.setState({
      detailsVisible: true,
    });
  };

  showFilterWindow = () => {
    this.setState({
      filterWindow: true,
    });
  };

  onClose = () => {
    this.setState({
      detailsVisible: false,
    });
  };

  editFormDetails = (id) => {
    this.onClose();
    this.handleEditOrderClick(id);
  };

  handleSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      routes: Object.assign({}, this.state.routes, {
        selectedOrderKeys: selectedRowKeys,
        selectedOrderRows: selectedRows,
      }),
    });
  };

  handleEditOrder = (id) => {
    const orderIndex = _.findIndex(this.state.orderInfo, ["id", id]);
    const currentOrder = Object.assign({}, this.state.orderInfo[orderIndex]);
    this.setState(
      {
        currentOrder,
      },
      () => {
        // this.setState({ editForm: true });
      }
    );
  };

  closeEditFormModal = () => {
    this.setState({ editForm: false }, () => {
      this.getOrders();
    });
  };

  closeFilterWindow = () => {
    this.setState({ filterWindow: false });
  };

  handleSearch = () => {
    let currentAccount = {};
    const accountIndex = _.findIndex(this.state.accounts, {
      code: this.state.filterPlaceHolder.accountCode,
    });
    if (accountIndex >= 0) {
      currentAccount = this.state.accounts[accountIndex];
    }
    let currentWarehouse = {};
    // const whIndex = _.findIndex(this.state.warehouses, {
    //   id: this.props.warehouseFilter.sele,
    // });
    // if (whIndex >= 0) {
    //   currentWarehouse = this.state.warehouses[whIndex];
    // }
    this.setState(
      {
        filter: this.state.filterPlaceHolder.filter,
        currentAccount,
        currentWarehouse,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getOrders();
        this.closeFilterWindow();
      }
    );
  };

  handleExport = () => {
    const { filterPlaceHolder, filter } = this.state;
    const order_number = filterPlaceHolder.search_order_token || "";
    const status = filter === "ALL" ? "" : filter;
    const formattedFromDate = formatDate(filterPlaceHolder.fromDate);
    const formattedToDate = formatDate(filterPlaceHolder.toDate);
    const accountCode = filterPlaceHolder.account_codes || "";
    // const warehouseId =  "";
    const searchKeyType = filterPlaceHolder.search_key_type || "";
    const searchKeyValue = filterPlaceHolder.search_key_value || "";
    const scheduleOrdersType = filterPlaceHolder.schedule_orders_type;
    const zone_ids =  filterPlaceHolder.zone_ids || []
    const zoneIds = !isEmpty(zone_ids) && 
  zone_ids.length ? zone_ids.map((zone) => zone.value) : [];
    const order_type= filterPlaceHolder.order_type || '';
    const vehicle_type = filterPlaceHolder.vehicle_type || '';
    const created_by  = filterPlaceHolder.created_by || '';
    const SearchType = filterPlaceHolder.search_type || "";
    const ScheduleDayFilter = "Custom";
    const sortByType = filterPlaceHolder.sortByType || "ascend";
    const statsOnly = false;
    const selectedWarehouses = this.props.warehouseFilter.selectedWarehouses;
    const emailIds = this.state.billingEmails || [];
    const OrdersCount = this.state.pagination.total_count
    const role = userStore.currentRole();
    let url = "";
    if (this.state.routes.selectedOrderKeys.length > 0) {
      url = `${AppConfig.baseUrl}/api/v2/export_customer_orders/customer_orders_export?customer_order_ids=${this.state.routes.selectedOrderKeys}`;
    } else if (order_number !== "") {
      url = `${AppConfig.baseUrl}/api/v2/export_customer_orders/customer_orders_export?customer_order_number=${order_number}&status=${status}&operation_code=${"DOCSV"}&stats_only=${statsOnly}&warehouse_ids=${selectedWarehouses}&current_role=${role}&order_count=${OrdersCount}`;
        if (!isEmpty(searchKeyType) && !isEmpty(searchKeyValue)) {
          url = `${url}&${searchKeyType}=${searchKeyValue}&search_type=${SearchType}&search_key=${searchKeyType}&search_value=${searchKeyValue}&schedule_orders_type=${scheduleOrdersType}&schedule_day_filter=${ScheduleDayFilter}&sort_by=${filterPlaceHolder.sortBy}&sort_order=${I18n.t(`general.sort_${sortByType}`)}&order_type=${order_type}&from_date=${formattedFromDate}&to_date=${formattedToDate}&account_code=${accountCode}&zone_ids=${zoneIds}&vehicle_type=${vehicle_type}&order_count=${OrdersCount}&created_by_ids=${created_by}`;
      }
    } else {
      url = `${AppConfig.baseUrl}/api/v2/export_customer_orders/customer_orders_export?status=${status}&from_date=${formattedFromDate}&to_date=${formattedToDate}&sort_by=${filterPlaceHolder.sortBy}&account_code=${accountCode}&schedule_orders_type=${scheduleOrdersType}&zone_ids=${zoneIds}&order_type=${order_type}&vehicle_type=${vehicle_type}&created_by_ids=${created_by}&schedule_day_filter=${ScheduleDayFilter}&sort_order=${I18n.t(`general.sort_${sortByType}`)}&operation_code=${"DOCSV"}&stats_only=${statsOnly}&order_count=${OrdersCount}&current_role=${role}`;
      if (!isEmpty(searchKeyType) && !isEmpty(searchKeyValue)) {
        url = `${url}&${searchKeyType}=${searchKeyValue}&search_type=${SearchType}`;
      }
      this.toggleEmailModal(false)
     
    }
    if (!_.isNil(selectedWarehouses)) {
      // const warehouseIds = JSON.parse(selectedWarehouses).map(
      //   (warehouse) => warehouse.key
      // );
      // url += `&warehouse_ids=${warehouseIds}`;
      url += `&warehouse_ids=${selectedWarehouses}`;
    }
    url += `&org_id=${userStore.getStateValue("selectedOrg")}&response_type=csv`;

    if(emailIds.length === 0 ){
      dynamicFileDownload(url , {} , null , 
        "get").then((result) => {
          if(!result.success){
          //  renderAlertMessage(result.errors)
            this.setState({ inProgress: false });
          }
      });
    } else {
      url=`${url}&emails=${emailIds}`;
      exportOrders(url).then((result) => {
        if (result.success) {
          this.setState({ inProgress: false });
          alertMessage((result.message || "File will be emailed to your inbox"),"success",5)
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
      
    }
    // window.open(url, "_blank");
  };

  handleOrderNumberSearch = () => {
    this.setState(
      {
        filter: "",
        currentAccount: {},
        currentWarehouse: {},
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getOrders();
        this.closeFilterWindow();
      }
    );
  };

  handleCancel = () => {
    this.setState({
      routes: Object.assign({}, this.state.routes, {
        selectedOrderKeys: [],
        selectedOrderRows: [],
      }),
    });
  };

  handleDeleteOrder = (id) => {
    this.setState({ inProgress: true });
    deleteOrder(id).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.deleted"));
        this.getOrders();
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  multipleOrderDeletion = () => {
    this.setState({
      inProgress: true,
    });
    const id = this.state.routes.selectedOrderKeys;
    deleteMultipleOrder(id).then((result) => {
      if (result.success) {
        this.setState({
          routes: {
            selectedOrderKeys: [],
            selectedOrderRows: [],
          },
        });
        alertMessage(I18n.t("messages.deleted"));
        this.getOrders();
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  setConfirmBulkDelete = () =>{
    this.setState({ confirmBulkDelete: true });
  }

  confirmMultipleOrderDeletion = () =>{
    return (
      <Modal
        title={null}
        visible={true}
        footer={null}
        closable={false}
        maskClosable={false}
        className="orderAlert"
      >
        <Result
          status="error"
          title="Delete !!"
          icon={<Icon type="question-circle" theme="twoTone" twoToneColor="red"/>}
          subTitle="Are you sure ?"
          extra={[
            <Button
              type="primary"
              key="cancel"
              onClick={() => {
                this.setState({ confirmBulkDelete: false });
              }}
            >
              No
            </Button>,
            <Button
              type="primary"
              className="buttonGreen"
              key="reload"
              onClick={() => {
                this.multipleOrderDeletion(); // Call the method
                this.setState({ confirmBulkDelete: false }); // Update state
              }}
            >
              Yes
            </Button>,
          ]}
        />
      </Modal>
    );
  }


  resetOrders = () => {
    this.setState(
      {
        routes: {
          selectedOrderKeys: [],
          selectedOrderRows: [],
        },
      },
      () => {
        this.getOrders();
      }
    );
  };

  saveColumnWidths = () => {
    const storableColumns = this.state.resizableColumns.map((col) => { return { key: col.key, width: col.width || 100 }; });
    localStorage.setItem(tableName, JSON.stringify(storableColumns));
    alertMessage("Saved successfully", "success");
  };

  renderColumnSaveButton = (style = {}) => {
    if (this.state.resizableColumns.length) {
      return (
        <Button
          type="default"
          onClick={ () => this.saveColumnWidths() }
          icon="save"
          style={ {
            background: "#593c27",
            color: "white",
            ...style,
          } }
        >
          Save Column Widths
        </Button>
      );
    }else return null;
  }

  handleMultipleOrdersStatusChange = (status) => {
    this.setState({
      loading: true,
    });
    const orderIds = this.state.routes.selectedOrderKeys || [];
    const data = { order_ids: orderIds, status };
    changeStatusOfOrders(data).then((result) => {
      const { orderInfo, pagination = {} } = this.state;
      if (result.success) {
        alertMessage(I18n.t("messages.processed"), 10);
        this.setState(
          {
            loading: false,
            routes: {
              selectedOrderKeys: [],
              selectedOrderRows: [],
            },
          },
          () => {
            if (
              pagination &&
              orderInfo.length === orderIds.length &&
              isEmpty(pagination.next_page) &&
              pagination.current_page !== 1
            ) {
              this.tableOptions.pagination.current =
                pagination.current_page - 1;
            }
            this.getOrders();
          }
        );
      } else if (result.errors) {
        if (result.errors.length > 0) {
          const errorValue = result.errors[0];
          if (_.isString(errorValue)) {
            this.setState({
              loading: false,
            });
            alertMessage(result.errors, "error", 10);
          } else if (_.isObject(errorValue)) {
            const routes = {
              selectedOrderKeys: [],
              selectedOrderRows: [],
            };
            const statusErrors = [];
            result.errors.forEach((errObj) => {
              if (_.isObject(errObj) && errObj.order_number) {
                const errorOrder = _.find(this.state.orderInfo, {
                  customer_order_number: errObj.order_number,
                });
                if (!isEmpty(errorOrder)) {
                  routes.selectedOrderKeys.push(errorOrder.id);
                  routes.selectedOrderRows.push(errorOrder);
                  statusErrors.push(errObj);
                }
              }
            });
            this.setState({
              routes,
              loading: false,
              statusErrors,
              showStatusErrors: true,
            });
            // alertMessage('One or more required fields are missing', 'error', 10);
          }
        }
        this.getOrders();
      }
    });
  };

  handleReceiveToConfirm = () => {
    const scheduledOrders = [];
    const routes = {
      selectedOrderKeys: [],
      selectedOrderRows: [],
    };
    const statusErrors = [];
    this.state.routes.selectedOrderKeys.forEach((orderId) => {
      const order = _.find(this.state.orderInfo, { id: orderId });
      if (!isEmpty(order) && _.isObject(order)) {
        if (order.appointments.length === 0) {
          routes.selectedOrderKeys.push(order.id);
          routes.selectedOrderRows.push(order);
          statusErrors.push({
            order_number: order.customer_order_number,
            errors: ["Appointment not yet scheduled"],
          });
        } else {
          scheduledOrders.push(orderId);
        }
      }
    });

    if (scheduledOrders.length > 0) {
      const data = { order_ids: scheduledOrders };
      this.setState({
        loading: true,
      });
      changeReceivedToVerify(data).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.processed"), 10);
          const { orderInfo, pagination = {} } = this.state;
          this.setState(
            {
              loading: false,
              routes,
              statusErrors,
              showStatusErrors: statusErrors.length > 0,
            },
            () => {
              if (
                pagination &&
                orderInfo.length === scheduledOrders.length &&
                isEmpty(pagination.next_page) &&
                pagination.current_page !== 1
              ) {
                this.tableOptions.pagination.current =
                  pagination.current_page - 1;
              }
              this.getOrders();
            }
          );
        } else {
          renderAlertMessage(result.errors)
          this.setState({
            loading: false,
            statusErrors,
            showStatusErrors: statusErrors.length > 0,
          });
        }
      });
    } else {
      this.setState({
        loading: false,
        statusErrors,
        showStatusErrors: statusErrors.length > 0,
      });
    }
  };

  handleSingleOrderStatusChangeCall = (id) => {
    const orderIndex = _.findIndex(this.state.orderInfo, ["id", id]);
    const orderObject = Object.assign({}, this.state.orderInfo[orderIndex]);
    this.setState({
      currentOrder: {
        id: id,
        currentOrderNumber: orderObject.customer_order_number,
      },
    });
    if (orderIndex >= 0) {
      this.setState({
        statusChangeModal: true,
        processOrders: [orderObject],
        hasBulkOrders: false,
        currentStatus: this.state.filterPlaceHolder.filter,
      });
    }
  };
  handleBulkOrderStatusChangeCall = () => {
    this.setState({
      currentStatus: this.state.filterPlaceHolder.filter,
      statusChangeModal: true,
      processOrders: [...this.state.routes.selectedOrderRows],
      hasBulkOrders: true,
    });
  };

  closeStatusChangeModal = () => {
    this.setState({
      statusChangeModal: false,
      currentStatus: "",
      processOrders: [],
      hasBulkOrders: false,
      currentOrder: {},
    });
  };

  renderStatusChangeModal = () => (
    <BaseModal
      title={`Change Status ${
        this.state.currentOrder && this.state.currentOrder.currentOrderNumber
          ? ` of #${this.state.currentOrder.currentOrderNumber}`
          : ""
      }`}
      onCancel={() => this.closeStatusChangeModal()}
      maskClosable={false}
      width={620}
      bodyStyle={{
        padding: 20
      }}
    >
      {
        this.props.currentOrg?.code === I18n.t('account.netmove_code') ? 
      <MoreChangeStatusForm
        currentStatus={this.state.currentStatus}
        saveStatus={this.callbackOfStatusChange}
        orderObject={this.state.orderObject}
        closeModal={this.closeStatusChangeModal}
        orders={this.state.processOrders}
        processErrors={this.processErrors}
        hasBulkOrders={this.state.hasBulkOrders}
        is_military_time={this.props.organizationSettings.is_military_time === "true"}

      />
      :
      <ChangeStatusForm
        currentOrg={this.props.currentOrg}
        currentStatus={this.state.currentStatus}
        saveStatus={this.callbackOfStatusChange}
        orderObject={this.state.orderObject}
        closeModal={this.closeStatusChangeModal}
        orders={this.state.processOrders}
        processErrors={this.processErrors}
        hasBulkOrders={this.state.hasBulkOrders}
        is_military_time={this.props.organizationSettings.is_military_time === "true"}

      />
     }

    </BaseModal>
  );

  handleSurveyDetails = (id) => {
    this.setState({
      showSurvey: true,
      surveyResponseId: id,
    });
  };

  closeSurveyDetails = () => {
    this.setState({
      showSurvey: false,
    });
  };

  closeCalenderViewModal = (fromCalenderComponent = false, data) => {
    if (fromCalenderComponent) {
      this.setState({
        showCalenderView: false,
        filter: data.orderStatus,
      });
      if (!isEmpty(data)) {
        const filterData = Object.assign({}, this.props.defaultFilterValues, {
          account_codes: [data.orderId],
          warehouse_id: data.warehouseId,
          fromDate: moment(data.orderDate, "YYYY-MM-DD"),
          toDate: moment(data.orderDate, "YYYY-MM-DD"),
          filter: data.orderStatus,
          [I18n.t("order.filters.schedule_orders.type")]: I18n.t(
            "order.filters.schedule_orders.scheduled_key"
          ),
        });

        this.updateFilter(filterData);
      }
    } else {
      this.setState({
        showCalenderView: false,
      });
    }
  };
  openCalenderViewModal = () => {
    this.setState({
      showCalenderView: true,
    });
  };

  callbackOfStatusChange = () => {
    this.setState(
      {
        loading: false,
        routes: {
          selectedOrderKeys: [],
          selectedOrderRows: [],
        },
        orderObject: {},
        currentStatus: "",
        statusChangeModal: false,
      },
      () => {
        this.getOrders();
      }
    );
  };

  processErrors = (errors) => {
    const routes = {
      selectedOrderKeys: [],
      selectedOrderRows: [],
    };
    if (errors?.length > 0) {
      errors.forEach((order) => {
        const errorOrder = _.find(this.state.orderInfo, {
          customer_order_number: order,
        });
        if (!isEmpty(errorOrder)) {
          routes.selectedOrderKeys.push(errorOrder.id);
          routes.selectedOrderRows.push(errorOrder);
        }
      });
    }
    this.setState(
      {
        routes,
      },
      () => {
        this.getOrders();
      }
    );
  };

  handleChange = (element, value, cb = null) => {
    this.setState({ [element]: value });
  };

  navigateToPreplan = () => {
    this.props.history.push("/preplan");
  };

  renderPreplanModal = () => (
    <BaseModal
      title={`${I18n.t("preplan.create")}`}
      onCancel={() => this.closePreplanModal()}
      width="80%"
      style={{ top: "30" }}
      maskClosable={false}
    >
      <PreplanForm vehicleTypes={this.state.vehicleTypes}/>
    </BaseModal>
  );
  closePreplanModal = () => {
    this.setState({ showPreplan: false });
  };
  showPreplanModal = () => {
    this.setState({
      showPreplan: true,
    });
  };

  handleShowInvoice = () => (
    // <PrintModal
    //   width={"70%"}
    //   style={{ top: 50 }}
    //   closeModal={this.closeInvoiceModel}
    //   hidePrintButton={true}
    //   title={`${
    //     this.state.currentInvoice.is_for_invoice_quote === "true"
    //       ? I18n.t("order.quote")
    //       : I18n.t("invoices.label")
    //   }
    //     ${
    //       this.state.currentInvoice &&
    //       `#${this.state.currentInvoice.order_number}`
    //     }`}
    // >
    // </PrintModal>
    <BaseModal
      title={`${
        this.state.currentInvoice?.is_for_invoice_quote === "true"
          ? I18n.t("order.quote")
          : I18n.t("invoices.label")
      }`}
      onCancel={() => this.closeInvoiceModel()}
      width={"70%"}
      style={{ top: 50 }}
    >
      <Invoice currentInvoice={this.state.currentInvoice} />
    </BaseModal>
  );

  printBol = () => {
    this.setState({
      isGeneratingPdf: true,
    });
    fetchBolPdf(this.state.currentOrder.id)
      .then((result) => {
        if (result.success) {
          base64ToPdf(
            result.bol_file,
            `Order_Bol_${this.state.currentOrder.currentOrderNumber}.pdf`
          );
        } else {
        }
      })
      .finally(() => {
        this.setState({
          isGeneratingPdf: false,
        });
      });
  };

  renderBillOfRatingModal = () => (
    <BaseModal
      title={`${I18n.t("BillOfRating.bill_of_rating_title")}: #${
        this.state.currentOrder.currentOrderNumber
      }`}
      onCancel={this.closeBillOfRatingModel}
      width={"70%"}
      style={{ top: 50 }}
    >
      {checkServiceExistance("GOBP") && (
        <div
          className="alignCenter marginBottom10"
          style={{ cursor: "pointer" }}
          onClick={this.printBol}
        >
          <Icon type="printer" /> Print
        </div>
      )}
      <Spin spinning={this.state.isGeneratingPdf} delay={1000}>
        <BillOfRatingModal orderId={this.state.currentOrder.id} order={this.state.currentOrder} />
      </Spin>
    </BaseModal>
  );

  handleGenerateInvoice = (id, isForQuote = "false") => {
    this.setState({ inProgress: true });
    saveInvoice({ order_id: id, is_for_invoice_quote: isForQuote }).then(
      (result) => {
        if (result.success) {
          if (isForQuote === "true") {
            alertMessage(I18n.t("messages.quote_generated"));
          } else {
            alertMessage(I18n.t("messages.invoice_generated"));
          }
          const invoice = result?.invoice ? result.invoice : {};
          invoice.is_for_invoice_quote = isForQuote;
          this.setState({
            currentInvoice: invoice,
            inProgress: false,
            showInvoiceModel: !!result.invoice,
          });
          this.getOrders();
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      }
    );
  };

  closeInvoiceModel = (id) => {
    this.setState({
      showInvoiceModel: false,
    });
  };

  closeBillOfRatingModel = () => {
    this.setState({
      showBillOfRatingModal: false,
    });
  };

  setRef = (ref) => (this.componentRef = ref);

  // clearFilter = () => {
  //   // const filter = defaultFilterValues;
  //   // const { filterPlaceHolder } = this.state;
  //   // this.setState({ filter: I18n.t("order.default_status") });
  //   // const prevSearchType = _.get(filterPlaceHolder, "search_type" , "EQUALS");

  //   const { filterPlaceHolder : savedFilter , setFilterPlaceHolder : updateSavedFilter } = this.props.orderConfigContext;
  //   // const newFilter = { ...filter, filter: this.state.filter, sortBy: filterPlaceHolder.sortBy, sortByType: filterPlaceHolder.sortByType, search_type: prevSearchType, search_key_type : filterPlaceHolder?.search_key_type ? filterPlaceHolder.search_key_type : filter.search_key_type };
  //   this.updateFilter(savedFilter);
  //   // this.props.warehouseFilter.onWarehouseChange("")
  // };


  getSelectedWarehouse = (selectedWarehouses) => {
    return _.isArray(selectedWarehouses) && selectedWarehouses.length > 0
      ? selectedWarehouses[ 0 ]
      : selectedWarehouses;
  };

  getFilteredZones = (delivery_zones, selectedwh) => {
    return delivery_zones
      .filter((zone) => zone.warehouse_id === selectedwh)
      .map((zone) => ({ label: zone.zone_name, value: zone.zone_id }));
  };

  getCurrentZones = (filteredZones, zone_ids) => {
    const zonesIds = zone_ids?.length > 0 ? zone_ids.split(",") : [];
    return filteredZones?.filter((zone) =>
      zonesIds.includes(zone.value)
    );
  };


  softClearFilter = () => {
    const { filterPlaceHolder: savedFilter, setFilterPlaceHolder: updateSavedFilter } = this.props.orderConfigContext;
    const filteredStatus = this.state.filterPlaceHolder.filter;
    const { currentUser } = this.props.userContext;
    const { searchCriteria, selectedWarehouses } = this.props.warehouseFilter;
    const selectedwh = this.getSelectedWarehouse(selectedWarehouses);
    const { orders = {} } = searchCriteria;
    const {
      account_code,
      from_date,
      order_type,
      to_date,
      zone_ids,
      schedule_orders_type,
      schedule_day_filter,
      created_by_ids,
    } = orders;

    const delivery_zones =
      currentUser?.delivery_zones?.length > 0 ? currentUser.delivery_zones : [];
    const filteredZones = this.getFilteredZones(delivery_zones, selectedwh);
    const curentZones = this.getCurrentZones(filteredZones, zone_ids);
    const { fromDate, toDate } = getFromToDate('Current Week', to_date, from_date);
    const filter = this.getFilterObject(fromDate, toDate, filteredStatus, account_code, savedFilter, schedule_orders_type, curentZones, order_type, created_by_ids);

    this.updateFilter(filter);
    updateSavedFilter(filter);
  }


  getFilterObject = (fromDate, toDate, filteredStatus, account_code, savedFilter, schedule_orders_type, curentZones, order_type, created_by_ids) => {
    return Object.assign({}, this.state.filterPlaceHolder, {
      fromDate,
      toDate,
      filter: filteredStatus,
      sortBy: "none",
      sortByType: "descend",
      account_codes: !isEmpty(account_code) ? account_code.split(",") : [],
      warehouse_id: "",
      search_type: _.isEmpty(savedFilter.search_type) ? "EQUALS" : savedFilter.search_type,
      search_key_type: _.isEqual(savedFilter.search_type, "CONTAINS") && !_.isEmpty(savedFilter.search_key_type) ? savedFilter.search_key_type : "",
      [ I18n.t("order.filters.schedule_orders.type") ]: !isEmpty(
        schedule_orders_type
      )
        ? schedule_orders_type
        : I18n.t("order.filters.schedule_orders.scheduled_key"),
      zone_ids: curentZones?.length > 0 ? curentZones : [],
      order_type: !isEmpty(order_type) ? order_type : "",
      schedule_day_filter: 'Current Week',
      search_order_token: '',
      search_key_value: '',
      created_by_ids: !isEmpty(created_by_ids) ? created_by_ids.split(",") : [],
    });
  };


  // softClearFilter = () => {
  //   // fill with savedfilter
  //   const { filterPlaceHolder: savedFilter, setFilterPlaceHolder: updateSavedFilter } = this.props.orderConfigContext;
  //   const filteredStatus = this.state.filterPlaceHolder.filter;
  //   const { currentUser } = this.props.userContext;
  //   const { searchCriteria, selectedWarehouses } = this.props.warehouseFilter;
  //   const selectedwh =
  //     _.isArray(selectedWarehouses) && selectedWarehouses.length > 0
  //       ? selectedWarehouses[ 0 ]
  //       : selectedWarehouses;
  //   const { orders = {} } = searchCriteria;
  //   const {
  //     account_code,
  //     from_date,
  //     order_type,
  //     to_date,
  //     zone_ids,
  //     schedule_orders_type,
  //     schedule_day_filter,
  //   } = orders;

  //   // filtering current warehouse zones then setting saved search Zones....
  //   const delivery_zones =
  //     currentUser?.delivery_zones?.length > 0 ? currentUser.delivery_zones : [];
  //   const filteredZones = delivery_zones
  //     .filter((zone) => zone.warehouse_id === selectedwh)
  //     .map((zone) => ({ label: zone.zone_name, value: zone.zone_id }));
  //   const zonesIds = zone_ids?.length > 0 ? zone_ids.split(",") : [];
  //   const curentZones = filteredZones?.filter((zone) =>
  //     zonesIds.includes(zone.value)
  //   );
  //   const { fromDate, toDate } = getFromToDate('Current Week', to_date, from_date);
  //   const filter = Object.assign({}, this.state.filterPlaceHolder, {
  //     fromDate,
  //     toDate,
  //     filter: filteredStatus,
  //     sortBy: "none",
  //     sortByType: "descend",
  //     account_codes: !isEmpty(account_code) ? account_code.split(",") : [],
  //     warehouse_id: "",
  //     search_type: _.isEmpty(savedFilter.search_type) ? "EQUALS" : savedFilter.search_type,
  //     search_key_type: _.isEqual(savedFilter.search_type, "CONTAINS") && !_.isEmpty(savedFilter.search_key_type) ? savedFilter.search_key_type : "",
  //     [ I18n.t("order.filters.schedule_orders.type") ]: !isEmpty(
  //       schedule_orders_type
  //     )
  //       ? schedule_orders_type
  //       : I18n.t("order.filters.schedule_orders.scheduled_key"),
  //     zone_ids: curentZones?.length > 0 ? curentZones : [],
  //     order_type: !isEmpty(order_type) ? order_type : "",
  //     schedule_day_filter : 'Current Week',
  //     search_order_token: '',
  //     search_key_value: '',
  //   });
  //   // console.log(savedFilter);
  //   this.updateFilter(filter);
  //   updateSavedFilter(filter);
  // }

  clearFilter = () => {
    const { filterPlaceHolder: savedFilter, setFilterPlaceHolder: updateSavedFilter, getDefaultGlobalFilter } = this.props.orderConfigContext;
    const { search_order_token, search_key_value } = this.state.filterPlaceHolder;
    const { fromDate, toDate } = getFromToDate('Current Week')
    const defaultGlobalFilter = getDefaultGlobalFilter(fromDate, toDate);
    if (search_order_token.length > 0 || search_key_value.length > 0) {
      this.updateFilter(defaultGlobalFilter);
      updateSavedFilter(defaultGlobalFilter);
    } else {
      const resettedFilter = { ...defaultGlobalFilter, search_order_token: '', search_key_value: '' };
      this.updateFilter(resettedFilter);
      updateSavedFilter(resettedFilter);
    }
  };

  updateFilter = (filter) => {
    this.handleFilterPlaceHolderChange(filter, () => {
      if (typeof this.props.handleChildFilterChange === "function"){
        this.props.handleChildFilterChange(filter);
      }     
    });
  };

  handleBarCodePrint = (visible) => {
    this.setState({ showBarCodeModal: visible });
  }; 

  // handleOnFilterElementChange = (element, value) => {
  //   console.log(this.state.filterPlaceHolder)
  //   if (element === "dateFilter") {
  //     const filter = Object.assign({}, this.state.filterPlaceHolder, {
  //       fromDate: value.fromDate,
  //       toDate: value.toDate,
  //     });
  //     this.updateFilter(filter);
  //   } else if (element === "search_key_type") {
  //     const filter = Object.assign({}, this.state.filterPlaceHolder, {
  //       search_key_type: value.search_key_type,
  //       search_key_value: value.search_key_type,
  //     });
  //     this.updateFilter(filter);
  //   } else if (element === "sortBy") {
  //     const filter = Object.assign({}, this.state.filterPlaceHolder, {
  //       sortBy: value.sortKey,
  //       sortByType: value.sortKeyType || "ascend",
  //     });
  //     this.updateFilter(filter);
  //   } else {
  //     const filter = Object.assign({}, this.state.filterPlaceHolder, {
  //       [element]: value,
  //     });
  //     this.updateFilter(filter);
  //   }
  // };

  handleOnFilterElementChange = (element, value) => {
    const filter = { ...this.state.filterPlaceHolder };
    switch (element) {
      case "dateFilter":
        filter.fromDate = value.fromDate;
        filter.toDate = value.toDate;
        break;
      case "search_key_type":
        filter.search_key_type = value.search_key_type;
        filter.search_key_value = value.search_key_type;
        break;
      case "sortBy":
        filter.sortBy = value.sortKey;
        filter.sortByType = value.sortKeyType || "ascend";
        break;
      default:
        filter[ element ] = value;
    }

    this.updateFilter(filter);
  };

  closeStatusErrors = () => {
    this.setState({
      statusErrors: [],
      showStatusErrors: false,
    });
  };

  renderStatusErrors = () => (
    <BaseModal
      title={I18n.t("errors.errors")}
      placement="right"
      closable
      onCancel={this.closeStatusErrors}
      visible={this.state.showStatusErrors}
      width="50%"
      style={{ top: 35 }}
    >
      {this.state.statusErrors.map((err) => (
        <Row>
          <Col xs={6} className="textBold">
            {err.order_number}
          </Col>
          <Col xs={18}>{_.isArray(err.errors) ? err.errors.join(",") : ""}</Col>
        </Row>
      ))}
    </BaseModal>
  );
  handleBillOfRating = (orderId, orderNumber) => {
    this.setState({
      currentOrder: { id: orderId, currentOrderNumber: orderNumber },
      showBillOfRatingModal: true,
    });
  };

  updateListEstimate = (orderId, updatedAmount) => {
    console.log("Order: Order ID::", orderId)
    console.log("updatedAmt::", updatedAmount);
      this.setState((prevState) => {
        const order_info = [...prevState.orderInfo];
        const orderIndex = order_info.findIndex(
          (order) => order.id === orderId
        );

        if (orderIndex >= 0) {
          order_info[orderIndex].estimate = updatedAmount;
          return { orderInfo: order_info };
        }

        // Return the existing state if the array is empty or index is out of bounds
        return prevState;
      });
  }

  setCurrentGridRef = (ref) => {
    this.setState({ currentGridRef: ref });
  };

  openRerrangeableModal = () => {
    this.setState({ showRearrangeableModal: true });
  }

  renderExtraHeader = () => (
    <Row
      type="flex"
      justify="end"
      gutter={ 8 }
    >
      { this.state.isUsingBetaVersion && (
        <>
          <Col>
            <Button onClick={ this.openRerrangeableModal } type='primary' size="small" >
              Rearrange Columns
            </Button>
          </Col>
          <Col>
            <Input
              size="small"
              placeholder="Quick Filter"
              onChange={ event => this.state.currentGridRef.setQuickFilter(event.target.value) }
            />
          </Col>
        </>
      ) }
      <Col>
        <span className="textBold">
          { I18n.t("general.sort_by") }: &nbsp;{ " " }
        </span>
        <OrderSortByOptions
          value={ this.state.filterPlaceHolder.sortBy }
          size="small"
          className=""
          styleObj={ { width: 150 } }
          onChange={ this.handleOnFilterElementChange }
        />
      </Col>
      <Col>
        { this.state.filter === "EXCEPTION" ? (
          <Col>
            <span className="textBold">Exception:&nbsp;</span>
            <Select
              style={ {
                float: "right",
                width: 150,
                paddingBottom: 12,
              } }
              showSearch
              allowClear={ true }
              placeholder="Filter By Code"
              onChange={ this.onChange }
              onSearch={ this.onSearch }
              value={ this.state.exception_selected_codes }
              filterOption={ (input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              size="small"
            >
              { this.state.exceptions.map((code) => (
                <Select.Option
                  key={ code.exception_code }
                  value={ code.exception_code }
                >
                  { code.exception_code }
                </Select.Option>
              )) }
            </Select>
          </Col>
        ) : (
          ""
        ) }
      </Col>
      <Col>
        <RecordsPerPage
          onChange={ this.onRecordChange }
          onSearch={ this.onSearch }
          value={ this.state.recordsPerPage }
          defaultValue={ this.state.recordsPerPage }
          isUsingBetaVersion={ this.state.isUsingBetaVersion }
        />
      </Col>
    </Row>

  )

  onGridReady = (params) => {
    this.setCurrentGridRef(params.api);
  };

  render() {
    const {
      displayConfiguration,
      ordersListProps, // Get ordersListProps from props
    } = this.props;
    const { orgContext, userContext } = this.props.appContexts;
    const { currentRole,currentOrg } = userContext;
    const { accountsExceeded, } = orgContext;
    const accountCodesWithExceededLimit = accountsExceeded.map(account => account.account_code)
    const isShipper = currentRole === "shipper";
    const { status, filter } = this.state;
    const pagination = {
      total: this.state.pagination.total_count,
      current: this.state.pagination.current_page,
      pageSize: this.state.pagination.per_page || this.state.recordsPerPage, // removed AppConfig.ordersPerPage to dynamically adjust record length 
    };
    const selectedRowKeys = this.state.routes.selectedOrderKeys;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleSelectChange,
    };
    const modifiedData = this.state.orderInfo.map((order) => {
      if (accountCodesWithExceededLimit.includes(order.account_code)) {
        return {
          ...order,
          account_limit_exceeded: true
        }
      } else {
        return order
      }
    }
    )
    return (
      <div className="content-outer routes-classes">
        <div className="content">
          <Row className="page-header">
            <Col xs={12}>
              {/* {isShipper && currentUser?.account_name ? `Orders of ${currentUser.account_name}`: I18n.t("menu.orders_management")} */}
              {I18n.t("menu.orders_management")}
            </Col>
            <>
            {!isShipper && <Col xs={12} className="textRight page-actions">
            {checkServiceExistance("DOCSV") &&
                this.state.orderInfo.length > 0 && (
                  <Tooltip title="Export">
                    <span className="filterSetIcon">
                      <img
                        src={excelIcon}
                        className="cursorPointer"
                        onClick={() => this.state.pagination?.total_count <= 100 ? this.handleExport() : this.toggleEmailModal(true)} 
                        style={{
                          width: 24,
                          marginTop: "-2px",
                        }}
                      />
                    </span>
                    &nbsp;&nbsp;
                  </Tooltip>
              )}
              {checkServiceExistance("WOS") && (
                <Tooltip title={I18n.t("order.weekly_scheduled_orders")}>
                  <span className="filterSetIcon">
                    <img
                      src={dateIcon}
                      className="cursorPointer"
                      onClick={this.openCalenderViewModal}
                      style={{ width: 24 }}
                    />
                  </span>
                </Tooltip>
              )}
              {checkServiceExistance(["CCO", "OPSPPS"], "ALL") && (
               <Button type="primary" onClick={() => {
                  this.props.history.push('/upload')
               }} icon='arrow-up' style={{ marginTop: "2px", marginLeft: 10 }}>
               {I18n.t('menu.upload_orders')}
              </Button>
              )}
              &nbsp;&nbsp;
              {checkServiceExistance("CCO") && (
                <Button
                  type="primary"
                  onClick={() => this.handleAddOrder()}
                  style={{ marginTop: "2px", marginLeft: 10 }}
                >
                  {I18n.t("order.create")}
                </Button>
              )}

              {checkServiceExistance(["PPC", "OPSPPS"], "ALL") && (
                <Button
                  type="primary"
                  onClick={() => this.showPreplanModal()}
                  style={{ marginTop: "2px" }}
                >
                  {I18n.t("preplan.create")}
                </Button>
              )}
            </Col>}
            {this.state.pagination.total_count >= 100 && (
            <Modal
              title="Export Orders"
              visible={this.state.isModalVisible}
              onCancel={() => this.toggleEmailModal(false)}
              onOk={this.handleExport}
            >
              <h4 style={{fontWeight:"bold",fontSize:"13px"}}>{I18n.t('general.export_orders')}</h4>
              <Col span={ 24 }>
            <FormItem label={I18n.t('general.emails')} className="tagsInput emailForm">
            <Select
                  value = {this.state.billingEmails}
                  onChange={this.addBilligEmail}
                  mode="tags"
                  maxTagCount={1}
                  validationRegex = {this.addBilligEmail}
                  tokenSeparators={[","]}
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Enter or select Emails"
                >
                  {userContext?.currentUser?.email ? (
                <Option key={userContext.currentUser.email}>
                  {userContext.currentUser.email}
                </Option>
              ) : ""}
                </Select>
                
                </FormItem>
          </Col>
            </Modal>
            )}
            </>
            
          </Row>
          <Spin spinning={this.state.statsProgress} delay={1000}>
            {checkServiceExistance(["COFS", "COI"], "ALL") && (
              <OrdersStats
                filter={this.state.filter}
                handleOnFilterChange={this.handleOnFilterChange}
                data={this.state.statsData}
              />
            )}
          </Spin>
          <Spin spinning={this.state.isLoading} delay={1000}>
            <Row>
              <Col className="marginTop marginBottom40">
                <Row>
                  <Col>
                    {checkServiceExistance(["COFILTER", "COI"], "ALL") && (
                      <AppliedFilters
                        handleOnFilterElementChange={
                          this.handleOnFilterElementChange
                        }
                        usersData = {this.state.users}
                        filterPlaceHolder={this.state.filterPlaceHolder}
                        currentAccount={this.state.currentAccount}
                        currentWarehouse={this.state.currentWarehouse}
                        filter={this.state.filter}
                        handleOnOrderTokenClose={this.handleOnOrderTokenClose}
                        clearFilter={this.clearFilter}
                        handleOnFilterChange={this.handleOnFilterChange}
                        accounts={this.state.accounts}
                        exception={this.state.exeCode}
                        warehouseFilter={this.props.warehouseFilter}
                        isShipper={isShipper}
                        softClearFilter={ this.softClearFilter }
                      />
                    )}
                  </Col>
                </Row>
                <Col className="ordersListSidebar"
                  style={{
                    height: `calc(100vh - ${ this.state.routes.selectedOrderKeys.length > 0 ? 410 : 375}px)`,
                  }}
                >
                  {checkServiceExistance("COI") && (
                    <>
                      {!this.state.isUsingBetaVersion ? (
                      <OrdersList
                        displayConfiguration={displayConfiguration}
                        showActions={!isShipper}
                        vhType={this.state.vehicleTypes}
                       
                        gotoOrderDetails={(id)=>this.gotoOrderDetails(id)}
                        organizationSettings={this.state.organizationSettings}
                        ordersListProps={ordersListProps}
                        data={modifiedData}
                        generateInvoice={this.handleGenerateInvoice}
                        deleteClick={(id) => this.handleDeleteOrder(id)}
                        moveToPending={(id) => this.handleExceptionOrder(id)}
                        assignDriver={(id) => this.assignDriver(id)}
                        processToNextStep={(id, orderStatus) =>
                          this.setChangeStatusValues(id, orderStatus)
                        }
                        rowClassName={(record, index) =>
                          record.account_limit_exceeded ? "errRow" : ""
                        }
                        // setResizablecolumns={(columns) => this.setState({ resizableColumns: columns })}
                        statusChange={(id) =>
                          this.handleSingleOrderStatusChangeCall(id)
                        }
                        orderDetailsViewClick={(id) =>
                          this.handleOrderDetailsViewClick(id)
                        }
                        navigateToRoute={(id, orderStatus, date) =>
                          this.navigateToRoute(id, orderStatus, date)
                        }
                        navigateToAccount={(account_code) =>
                          this.navigateToAccount(account_code)
                        }
                        handleEditOrder={(id) => this.handleEditOrderClick(id)}
                        handleSurveyDetails={this.handleSurveyDetails}
                        rowSelection={
                          !isShipper && deletionStatus.includes(this.state.filter)
                            ? rowSelection
                            : null
                        }
                        screen_from="orders"
                        scroll={{ y: `calc(100vh - ${ this.state.routes.selectedOrderKeys.length > 0 ? 450 : 375 }px)` }}
                        pagination={pagination}
                        tableChange={(
                          pagination,
                          filter,
                          sorter,
                          currentTable
                        ) =>
                          this.onTableChange(
                            pagination,
                            filter,
                            sorter,
                            currentTable
                          )
                        }
                        currentPage={
                          pagination.current ? pagination.current : 1
                        }
                        size="small"
                        currentFilter={this.state.filter}
                        handleBillOfRating={this.handleBillOfRating}
                        resolvedAddressCallback={this.getOrders}
                        refreshCallback={this.getOrders}
                        isRowSelected={
                          this.state.routes.selectedOrderKeys.length > 0
                        }
                        accounts={this.state.accounts}
                        // showTriggerEdit= {true}
                        // showDetailsLink= {false}
                        bodyStyle={{ paddingBottom: 10 }}
                        isShipper={isShipper}
                        renderExtraHeader={ this.renderExtraHeader }
                        filterPlaceHolder={this.state.filterPlaceHolder}
                        currentOrg={currentOrg}
                        updateListEstimate={this.updateListEstimate}
                      />
                      ) : (
                          <Row>
                            <Col span={ 24 }>
                              { this.renderExtraHeader() }
                            </Col>
                            <Col span={ 24 } style={ { height: `calc(100vh - ${this.state.routes.selectedOrderKeys.length > 0 ? 395 : 375}px)` } }>
                              <UnallocatedOrdersGridList
                              // {...orderListProps}
                                rawDataSource={ this.state.orderInfo }
                                accountCodesWithExceededLimit={ [] }
                                onRowSelection={ () => { } }
                                accountsList={ [] }
                                displayConfiguration={ {} }
                                showTriggerEdit={ true }
                                showDelete={ false }
                                showActions={ false }
                                showDetailsLink={ false }
                                showWeight={ true }
                                hideAppointmentdate={ true }
                                tableName={ ScreenKeys.ORDER_LISTING_V2 }
                                showRerrangeableModal={ this.state.showRearrangeableModal }
                                // setCurrentGridRef={ (ref) => this.setCurrentGridRef(ref) }
                                onGridReady={ this.onGridReady }
                                updateListEstimate={this.updateListEstimate}
                              />
                            </Col>
                          </Row>
                      )}
                    </>
                  )}
                </Col>
              </Col>
            </Row>

            { this.state.routes.selectedOrderKeys.length > 0 &&
              deletionStatus.includes(this.state.filter) && (
                <OrderBulkOperationContainer 
                handleCancel={ this.handleCancel }
                selectedOrders={ this.state.routes.selectedOrderKeys }
                resetOrders={ this.resetOrders }
                routes={ this.state.routes }
                filter={ filter }
                warehouseFilter={ this.props.warehouseFilter }
                handleMultipleOrdersStatusChange={
                  this.handleMultipleOrdersStatusChange
                }
                handleReceiveToConfirm={ this.handleReceiveToConfirm }
                changeStatus={ this.handleBulkOrderStatusChangeCall }
                statusChangeModal={this.state.statusChangeModal}
                handleEditOrder={ this.handleEditOrderClick }
                handleBarCodePrint={ this.handleBarCodePrint }
                showBarCodeModal={ this.state.showBarCodeModal }
                getOrders={this.getOrders}
                setConfirmBulkDelete={ this.setConfirmBulkDelete}
                confirmBulkDelete={this.state.confirmBulkDelete}
                handleExportToExcel={() => this.state.pagination?.total_count <= 100 ? this.handleExport() : this.toggleEmailModal(true)}
                isModalVisible={this.state.isModalVisible}
                loading={ this.state.isLoading }
                refreshCallback={this.getOrders}
                userContext={userContext}
              />
              // <OrdersBulkOperations
              //     filter={ filter }
              //     routes={ this.state.routes }
              //     loading={ this.state.isLoading }
              //     multipleOrderDeletion={ this.multipleOrderDeletion }
              //     handleCancel={ this.handleCancel }
              //     resetOrders={ this.resetOrders }
              //     handleMultipleOrdersStatusChange={
              //       this.handleMultipleOrdersStatusChange
              //     }
              //     handleReceiveToConfirm={ this.handleReceiveToConfirm }
              //     changeStatus={ this.handleBulkOrderStatusChangeCall }
              //     handleEditOrder={ this.handleEditOrderClick }
              //     warehouseFilter={ this.props.warehouseFilter }
              //     handleBarCodePrint={ this.handleBarCodePrint }
              //     // renderColumnSaveButton={ this.renderColumnSaveButton }
              //   />
            // ) : (
            //   <Row className="bottom_fixed">
            //     <Col xs={ 24 } className="alignCenter">
            //       { this.renderColumnSaveButton() }
            //     </Col>
            //   </Row>
            ) }

          </Spin>
        </div>

        {this.state.detailsVisible && (
          <Drawer
            title={
              <OrderTitle
                order={this.state.orderObject}
                showEdit={true}
                editClick={() =>
                  this.editFormDetails(this.state.orderObject.id)
                }
              />
            }
            placement="right"
            closable
            onClose={this.onClose}
            visible={this.state.detailsVisible}
            width="85%"
          >
            <OrderDetails
              order_id={this.state.orderObject.id}
              surveyDetails={this.handleSurveyDetails}
              handleSearch={this.handleSearch}
            />
          </Drawer>
        )}

        {this.state.statusChangeModal && this.renderStatusChangeModal()}

        {this.state.editForm && (
          <Drawer
            placement="right"
            closable={false}
            maskClosable={false}
            onClose={this.closeEditFormModal}
            visible={this.state.editForm}
            width="90%"
          >
            <EditOrderForm
              customerDetails={this.state.orderObject}
              customerOrderId={this.state.orderObject.id}
              templateId={this.state.orderObject.template_id}
              preferences={[]}
              onCancel={this.closeEditFormModal}
              handleSuccess={this.handleSaveSuccess}
              isNew={this.state.isNew}
            />
          </Drawer>
        )}

        {this.state.filterWindow && (
          <Drawer
            title="Filter By"
            placement="right"
            closable
            onClose={this.closeFilterWindow}
            visible={this.state.filterWindow}
            width="30%"
          >
            <OrderFilter
              filter={this.state.filterPlaceHolder}
              onChange={this.handleFilterPlaceHolderChange}
              onSearch={this.handleSearch}
              onOrderNoSearch={this.handleOrderNumberSearch}
              showOrderNoSearch={false}
              warehouses={this.props.warehouseFilter.warehouses}
            />
          </Drawer>
        )}

        {this.state.showSurvey && (
          <BaseModal
            title="Survey Details"
            onCancel={() => this.closeSurveyDetails()}
            className="surveyModal"
            width="70%"
            style={{ top: 40, overflowY: "auto" }}
          >
            <SurveyResponse surveyId={this.state.surveyResponseId} />
          </BaseModal>
        )}
        {this.state.showCalenderView && (
          <BaseModal
            title={I18n.t("order.weekly_scheduled_orders")}
            onCancel={() => this.closeCalenderViewModal()}
            className="surveyModal"
            width="90%"
            style={{ top: 20, overflowY: "auto" }}
          >
            <CalendarContainer
              getOrderDetails={(id) => this.handleOrderDetailsViewClick(id)}
              handleSurveyDetails={this.handleSurveyDetails}
              closeCalender={(fromCalenderComponent, data) =>
                this.closeCalenderViewModal(fromCalenderComponent, data)
              }
              warehouses={this.props.warehouseFilter.warehouses}
            />
          </BaseModal>
        )}
        {this.state.showPreplan && this.renderPreplanModal()}
        {this.state.showInvoiceModel && this.handleShowInvoice()}
        {this.state.showStatusErrors && this.renderStatusErrors()}
        {this.state.showBillOfRatingModal && this.renderBillOfRatingModal()}
        {this.state.confirmBulkDelete && this.confirmMultipleOrderDeletion()}
        { this.state.showBarCodeModal && (
          <BarCodeModal
            title="Print Barcodes"
            placement="right"
            closable
            onCancel={ () => this.handleBarCodePrint(false) }
            visible={ this.state.showBarCodeModal }
            style={ { top: 35 } }
            width="70%"
            selectedOrders={ this.state.routes.selectedOrderRows }
            org_id={ this.props.currentOrg.id }
            handleBarCodePrint={ this.handleBarCodePrint }
          />
        ) }
      </div>
    );
  }
}

export const Orders = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const displayConfiguration = useContext(DisplaySettingsContext);
  const [ordersListProps, setOrdersListProps] = useState({
    displayConfiguration,
  });
  const userContext= useContext(UserContext);
  const { currentOrg } = userContext;
  const orderConfigContextData = useContext(OrderConfigContext);
  const perPageKey = ScreenKeys.ORDER_LISTING
  const recordsPerPage = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);
  useEffect(() => {
    setOrdersListProps({
      displayConfiguration,
    });
  }, [displayConfiguration]);

  const { filterPlaceHolder, getDefaultGlobalFilter } = orderConfigContextData;
  const { fromDate, toDate } = getFromToDate('Current Week');
  const defaultGlobalFilter = getDefaultGlobalFilter(fromDate, toDate);
  return (
    <OrdersComponent
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      displayConfiguration={displayConfiguration}
      ordersListProps={ordersListProps}
      currentOrg={ currentOrg }
      userContext={userContext}
      orderConfigContext = {orderConfigContextData}
      recordsPerPage={recordsPerPage}
      {...props}
      defaultFilterValues={ defaultGlobalFilter }
      filterPlaceHolder={filterPlaceHolder}
    />
  );
});

export default Orders;

Orders.propTypes = {
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  filterPlaceHolder: PropTypes.shape(),
  handleChildFilterChange: PropTypes.func.isRequired,
};

Orders.defaultProps = {
  filterPlaceHolder: {},
};