/* eslint-disable camelcase */
import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon, Row, Col, Input, message } from '../../common/UIComponents';
import AddressInfo from '../common/AddressInfo';
import { alertMessage, isEmpty } from '../../common/Common';
import { formatUSPhoneNumber } from '../../helpers/common';
import { updateDriverRate } from '../../api/BillingScreenAPI';
import EditIcon from '../common/EditIcon';
import { fetchBillingDetails } from '../../api/Billing';

const InvoiceContact = ({
  data, displayType = 'line', showIcon = false, showAddr = false, driverNo = "", orderNo="", updateParentComponent
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedRate, setEditedRate] = useState(null);
  const [rateType, setRateType] = useState(null);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleEdit = (rateType, rateValue) => {
    setIsEditing(true);
    setRateType(rateType);
    setEditedRate(rateValue.toString());
  };

  const handleSave = useCallback(async () => {
    if (!editedRate || editedRate.trim() === '') {
      alertMessage("Can't be empty", "error");
      return;
    }

    const newRate = parseFloat(editedRate);
    if (isNaN(newRate)) {
      alertMessage("Please enter a valid driver rate", "error");
      return;
    }

    if (newRate < 0) {
      alertMessage("Driver rate cannot be negative", "error");
      return;
    }

    if (newRate === data.driver_rates.driver_charge_value) {
      alertMessage("No changes detected", "info");
      setIsEditing(false);
      return;
    }

    const payload = {
      driver_id: data.driver_id,
      customer_order_id: orderNo,
      location_id: data.driver_rates.location_id,
      updated_driver_charge_value: parseFloat(editedRate),
    };

    try {
      await updateDriverRate(payload);
      if (isMounted.current) {
      setIsEditing(false);
      setEditedRate(null);
      alertMessage('Driver rate updated successfully', "success");
      
      const updatedBillingDetails = await fetchBillingDetails(orderNo);
      if (updatedBillingDetails.success && isMounted.current) {
        updateParentComponent(updatedBillingDetails.invoice);
      }
      }
    } catch (error) {
      console.error('Error updating driver rate:', error);
      if (isMounted.current) {
        alertMessage('Failed to update driver rate', "error");
      }
    }
  }, [editedRate, data, orderNo, updateParentComponent]);

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedRate(null);
    setRateType(null);
  };

  const renderRateRow = (rate) => (
    <Row key={rate.segment_type}>
      <Col xs={24} className="main_heading textBold fontSize13">
        {isEditing && rateType === rate.segment_type ? (
          <>
            <Input
              size="small"
              value={editedRate}
              onChange={(e) => setEditedRate(e.target.value)}
              onKeyPress={handleInputKeyPress}
              style={{ width: '100px', marginRight: '8px' }}
            />
            <Icon
              type="save"
              theme="twoTone"
              onClick={handleSave}
              style={{ fontSize: 14, marginRight: '8px' }}
            />
            <Icon
              type="close"
              onClick={handleCancel}
              style={{ fontSize: 14 }}
            />
          </>
        ) : (
          <>
            {rate.driver_charge_type.toLowerCase() === 'percentage' ? (
              <Icon type="percentage" />
            ) : (
              <Icon type="dollar" />
            )}
            &nbsp;
            {rate.driver_charge_value}
            &nbsp;
            <EditIcon
              handleClick={() => handleEdit(rate.segment_type, rate.driver_charge_value)}
              style={{ marginLeft: '10px', marginRight: '8px' }}
              color="blue"
            />
          </>
        )}
      </Col>
    </Row>
  );

    return (
      <div>
        {
          showAddr && (<AddressInfo
          address={
            !isEmpty(data.billing_address)
              ? data.billing_address
              : !isEmpty(data.address)
              ? data.address
              : 'NA'
          }
          showIcon={showIcon}
          displayType={displayType}
        />
      )}
      {!isEmpty(driverNo) && (
        <Row>
          <Col xs={24}>
            {showIcon && (<Fragment><Icon type="phone" /> &nbsp;</Fragment>)}
            {formatUSPhoneNumber(driverNo)}
          </Col>
        </Row>
   )}
      {data.driver_rates && renderRateRow(data.driver_rates)}
      {!isEmpty(data.email) &&(
          <Row>
            <Col xs={24}>{showIcon && (<Fragment><Icon type="mail" /> &nbsp; </Fragment>)}
              {data.email}
            </Col>
          </Row>
        )}
      </div>
    );
};

export default InvoiceContact;

InvoiceContact.propTypes = {
  data: PropTypes.shape({
    billing_address: PropTypes.object,
    address: PropTypes.object,
    email: PropTypes.string,
    driver_id: PropTypes.string,
    driver_rates: PropTypes.shape({
      driver_charge_type: PropTypes.string,
      driver_charge_value: PropTypes.number,
      location_id: PropTypes.string,
      segment_type: PropTypes.string,
    }),
  }).isRequired,
  displayType: PropTypes.string,
  showIcon: PropTypes.bool,
  showAddr: PropTypes.bool,
  driverNo: PropTypes.string,
  orderNo: PropTypes.string.isRequired,
  updateParentComponent: PropTypes.func.isRequired,
};

InvoiceContact.defaultProps = {
  displayType: 'line',
  showIcon: false,
  showAddr: false,
  driverNo: "",
  orderNo: ""
};
