import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Col,
  Row,
  Search,
  Icon,
  Spin,
  Button,
  Popconfirm,
  Tabs,
} from "../../common/UIComponents";
import SubOrdersIndexList from "./SubORdersIndexList";
import userStore from "../../stores/UserStore";
import {
  deleteSubOrders,
  getSearchValueSubOrders,
  saveSubOrders,
  updateSubOrders,
} from "../../api/OrdersApi";
import {  alertMessage, isEmpty, navigateToAccount } from "../../common/Common";
import I18n from "../../common/I18n";
import BaseModal from "../BaseModal";
import FormErrors from "../common/FormErrors";
import { fetchShortFormAccounts } from "../../api/Account";
import { getValueFromArrayOfObjects } from "../../helpers/array_functions";
import { fetchAccountShortLos } from "../../api/Los";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { UserContext } from "../../context/UserContext";
import { renderAlertMessage } from "../../helpers/common";
import { ConsolidatedMAWBs } from "./ConsolidatedMAWBs";


const SubORdersForLh = (props) => {
  const [accounts, setAccounts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [lineHaulOrders, setLineHaulOrders] = useState(
    props.customerDetails.lh_orders
  );
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const warehouseId = props.customerDetails.wh_location?.id;
  const targetWhLocationId = props.customerDetails.locations?.find(location => location.type_of_loc === "DELIVERY")?.target_wh_location_id;
  const [selectedInfo, setSelectedInfo] = useState({
    selectedKeys: [],
    selectedRows: [],
  });
  const { currentOrg }= useContext(UserContext);
  const [selectedRecordsToDelete, setSelectedRecordsToDelete] = useState({
    selectedKeys: [],
    selectedRows: [],
  });
  const [errorsDialouge, setErrorsDialouge] = useState([]);
  const handleSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedInfo({
      ...selectedInfo,
      selectedKeys: selectedRowKeys,
      selectedRows: selectedRows,
    });
  };
  const [accountLOS, setAccountLOS] = useState({});
  const handleSelectDeleteChange = (selectedRowKeys, selectedRows) => {
    setSelectedRecordsToDelete({
      ...selectedRecordsToDelete,
      selectedKeys: selectedRowKeys,
      selectedRows: selectedRows,
    });
  };
  const [indexLoading, setIndexLoading] = useState(false);
  const [tabKey, setTabKey] = useState(props.linehaulTab ? "1" : "2");

  useEffect(() => {
    if(props.mawbButtonClicked){
      setTabKey("2");
    }
  }, [props.mawbButtonClicked]);

  const handleTextChange = (e) => {
    setSearchText(e.target.value);
  };
  const handleSuborderSave = () => {
    const invalidOrders = lineHaulOrders.filter(order => 
      isEmpty(order.target_account_id) || (isEmpty(order.target_los_id) && order.target_los_id !== "NONE")
    );
    if (invalidOrders.length > 0) {
      if (invalidOrders.some(order => isEmpty(order.target_account_id))) {
        alertMessage("Target Account is required", "error", 5);
      }
      if (invalidOrders.some(order => isEmpty(order.target_los_id))) {
        alertMessage("Target LOS is required", "error", 5);
      }
      return;
    }
  
    const payload = {
      warehouse_ids : warehouseId,
      line_haul_id: props.customerDetails.id,
      sub_orders: lineHaulOrders
        .filter(order => !isEmpty(order.target_account_id) && !isEmpty(order.target_los_id))
        .map(order => ({
          target_account: order.target_account_id,
          target_los: order.target_los_id,
          order_id: order._id || order.id,
        })),
    };
    setLoading(true);
    updateSubOrders(payload).then((res) => {
      setLoading(false);
      if (res.success) {
        setLineHaulOrders([].concat(lineHaulOrders, selectedInfo.selectedRows));
        alertMessage("Line haul suborders update successful", "success", 5);
      } else {
        alertMessage(res.error, "error", 5);
      }
    });
  };

  const onCancel = () => {
    setShowModal(false);
    setSelectedInfo({
      selectedKeys: [],
      selectedRows: [],
    });
    setErrorsDialouge([]);
    setSearchResults([]);
    setSearchText("");
  };

  const handleClear = () => {
    setSelectedRecordsToDelete({
      selectedKeys: [],
      selectedRows: [],
    });
  };
  const getAccounts = () => {
    const orgId  = currentOrg._id;
    const targetWhLocation = targetWhLocationId ? targetWhLocationId : warehouseId
    if(targetWhLocation) {
      setLoading(true);
      fetchShortFormAccounts(orgId,targetWhLocation,false)
      .then((result) => {
        if (result.success) {
          setAccounts(result.accounts);
          setLoading(false);
        } else {
          setAccounts([]);
          setLoading(false);
        }
      });
    }
  }
  useEffect(() => {
    getAccounts();
    setTargetData();
    props.fetchData()
  }, []);
  const setTargetData = () => {
    setLosOfAccounts();
  }
  const setLosOfAccounts = async () => {
    const record = {};
    try {
      lineHaulOrders.forEach(async order => {
          const response = await fetchAccountShortLos(order.target_account_code, 'LH');
          if (response.success) {
            record[order.target_account_id] = response.account.account_los || [];
        }
      } )
    } catch (error) {
      console.error('Error processing lineHaulOrders:', error);
    }
  
    setAccountLOS(record);
  }
  const handleUpdateOrder = (index, id, element, value) => {
    const orders = [...lineHaulOrders];
    orders[index][element] = value;
    if(element === "target_account_id"){
      orders[index]["target_los_id"] = "";
      const targetObject = value ? _.find(accounts, { id: value}) : null;
      if(value && targetObject){
        getTargetLos(value, targetObject.code);
      }
    }
    setLineHaulOrders(orders)
  }
  const getTargetLos = (accountId, accountCode) => {
    if (!isEmpty(accountCode) && !accountLOS[accountId]) {
      setLoading(true);
      fetchAccountShortLos(accountCode, 'LH').then((result) => {
        if (result.success) {
          const los = result.account.account_los || [];
          setAccountLOS({...accountLOS, [accountId]: los})
          setLoading(false);
        } else {
          setLoading(false);
          renderAlertMessage(result.errors)
        }
      });
    }
  };

  const findSubOrders = () => {
    if (searchText !== "") {
      const organization_id = userStore.getStateValue("selectedOrg");
      const search_value = searchText;
      setLoading(true);
      getSearchValueSubOrders(search_value, organization_id, warehouseId).then(
        (res) => {
          if (!_.isEmpty(res.orders)) {
            setSearchResults(res.orders);
            setLoading(false);
          } else {
            setLoading(false);
            alertMessage(
              "No orders found (or) Order(s) are already assigned.",
              "error",
              10 
            );
          }
        }
      );
    } else {
      alertMessage("Please enter your required number..!!", "error", 5);
    }
  };

  const handleAddClick = () => {
    setShowModal(true);
  };

  const handleTabChange = (key) => {
    setTabKey(key);
  };

  const fetchOrdersModal = () => {
    return (
      <BaseModal
        title="Search Results"
        visible={showModal}
        onCancel={onCancel}
        width={850}
        onOk={handleSave}
        maskClosable={false}
        footer={[
          <Row type="flex" justify="center">
            <Button key="back" onClick={onCancel} icon="close">
              Cancel
            </Button>
            <Button key="submit" type="primary" onClick={handleSave} disabled={!selectedInfo.selectedKeys.length} icon="save">
              Save
            </Button>
          </Row>,
        ]}
      >
        <Row>
          <Col span={24} className="alignCenter">
            <Search
              placeholder={I18n.t("order.filters.placeholder")}
              onSearch={findSubOrders}
              onChange={handleTextChange}
              value={searchText}
              autoFocus
              suffix={
                searchText !== "" && (
                  <Icon
                    type="close"
                    onClick={() => {
                      setSearchText("");
                      setSearchResults([]);
                    }}
                  />
                )
              }
              enterButton={
                <span>
                  <Icon type="search" /> Search
                </span>
              }
              style={{ width: 600 }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Spin spinning={loading}>
            <SubOrdersIndexList
              data={searchResults ? searchResults : []}
              pagination={{ position: "none" }}
              scroll={{ y: 300, x: 'max-content' }}
              getAccounts = {getAccounts}
              getTargetLos = {getTargetLos}
              rowSelection={{
                selectedRowKeys:
                  selectedInfo.selectedKeys.length > 0
                    ? selectedInfo.selectedKeys
                    : [],
                onChange: handleSelectChange,
                getCheckboxProps: (record) => ({
                  id: `${record.order_id}`,
                }),
              }}
              handleUpdateOrder={handleUpdateOrder}
              accounts={accounts}
              accountLOS={accountLOS}
              navigateToAccount={(account_id) => navigateToAccount(props.accounts, account_id, props.history)}
              isReadOnly = {!props.dontShowtheSearchBar ? true : false}
            />
          </Spin>
        </Row>
        {errorsDialouge.length > 0 && FormErrors(errorsDialouge)}
      </BaseModal>
    );
  };

  const handleSave = () => {
    const organization_id = userStore.getStateValue("selectedOrg");
    const payload = {
      organization_id: organization_id,
      warehouse_ids: warehouseId,
      id: props.customerDetails.id,
      lh_add_order_ids: selectedInfo.selectedKeys,
    };
    setIndexLoading(true);
    setLoading(true);
    saveSubOrders(payload).then((res) => {
      if (res.success) {
        setIndexLoading(false);
        setLoading(false);
        setLineHaulOrders([].concat(lineHaulOrders, selectedInfo.selectedRows));
        setShowModal(false);
        setSelectedInfo({
          selectedKeys: [],
          selectedRows: [],
        });
        setSearchText("");
        setSearchResults([]);
        alertMessage(res.data.message, "success", 10);
      } else {
        setIndexLoading(false);
        setLoading(false);
        setErrorsDialouge(res.errors);
        // alertMessage(res.errors[0], "error", 10);
      }
    });
  };

  const handleDelete = () => {
    const newLineHaulOrders = lineHaulOrders.filter(
      (item) => !selectedRecordsToDelete.selectedKeys.includes(item.id)
    );
    setLineHaulOrders(newLineHaulOrders);
    const organization_id = userStore.getStateValue("selectedOrg");
    const payload = {
      organization_id: organization_id,
      warehouse_ids: warehouseId,
      id: props.customerDetails.id,
      lh_dlt_order_ids: selectedRecordsToDelete.selectedKeys,
    };
    setIndexLoading(true);
    deleteSubOrders(payload).then((res) => {
      if (res.success) {
        setIndexLoading(false);
        setSelectedRecordsToDelete({
          selectedKeys: [],
          selectedRows: [],
        });
        alertMessage(res.data.message, "success", 10);
      } else {
        setIndexLoading(false);
        alertMessage("Something went wrong..!!", "error", 10);
      }
    });
  };

  const renderContent = () => {
    return (
      <>
        <Tabs activeKey={tabKey} onChange={handleTabChange}>
          {props.linehaulTab && (
            <Tabs.TabPane tab="LineHaul Orders" key="1">
              <SubOrdersIndexList
                data={lineHaulOrders ? lineHaulOrders : []}
                scroll={{
                  y: props.fromIndividualScreen
                    ? "calc(100vh - 355px)"
                    : "calc(100vh - 270px)",
                  x: "max-content",
                }}
                pagination={{ position: "none" }}
                handleDelete={handleDelete}
                rowSelection={
                  !["DISPATCHED", "COMPLETED"].includes(
                    props.customerDetails.status
                  ) && {
                    selectedRowKeys:
                      selectedRecordsToDelete.selectedKeys.length > 0
                        ? selectedRecordsToDelete.selectedKeys
                        : [],
                    onChange: handleSelectDeleteChange,
                    getCheckboxProps: (record) => ({
                      id: `${record.id}`,
                    }),
                  }
                }
                handleUpdateOrder={handleUpdateOrder}
                accounts={accounts}
                isReadOnly={!props.dontShowtheSearchBar && !["DISPATCHED", "COMPLETED"].includes(props.customerDetails.status) ? false : true}
                accountLOS={accountLOS}
                navigateToAccount={(account_id) =>
                  navigateToAccount(props.accounts, account_id, props.history)
                }
              />
            </Tabs.TabPane>
          )}
          {props.isConsolidatedPresent && (<Tabs.TabPane tab="Consolidated Orders" key="2">
            <ConsolidatedMAWBs
             customerDetails={props.customerDetails}
             navigateToAccount={(account_id) => navigateToAccount(props.accounts, account_id, props.history, props.location.pathname)}
             consolidatedData ={props.consolidatedData}
             consolidateLoading = {props.consolidateLoading}
             allStatus = {props.allStatus}
             />
          </Tabs.TabPane>)}
        </Tabs>
      </>
    );
  };
  

  return (
    <div className="content-outer">
      <div className="content">
        {!props.dontShowtheSearchBar && 
          <Fragment>
          {(!["DISPATCHED", "COMPLETED"].includes(
            props.customerDetails.status
          ) && (
            <Row style={{ marginBottom: "20px" }}>
              <Col offset={3} md={18} className="alignRight">
                <Button type="primary" onClick={handleAddClick} icon="plus">
                  {I18n.t("general.add")}
                </Button>
              </Col>
            </Row>
          ))}
          </Fragment>
        }
       
         <Spin spinning={indexLoading}>
          <Row>
            <Col md={24}>
              {renderContent()}
            </Col>
          </Row>
        </Spin>
        <Fragment>
            <Row style={{ marginTop: "20px" }}>
            <Col >
              <Row type="flex" justify="center" gutter={8}>
              {!props.dontShowtheSearchBar && (
                <>
                {(!["DISPATCHED", "COMPLETED"].includes(
            props.customerDetails.status
          ) && (
                <Col>
                  <Button type="primary" onClick={handleSuborderSave} size="default" icon='save'>
                    Save
                  </Button>
                </Col>
          ))}
          </>
        )}
        {selectedRecordsToDelete.selectedRows.length > 0 && (
          <>
                <Col>
                  <Button onClick={handleClear} size="default">
                    {I18n.t("general.clear")}
                  </Button>
                </Col>
                <Col>
                  <Popconfirm
                    title="Are you sure you want to delete?"
                    onConfirm={handleDelete}
                    okText="Yes"
                    cancelText="No"
                    placement="right"
                  >
                    <Button type="danger" size="default">
                      {I18n.t("general.delete")}
                    </Button>
                  </Popconfirm>
                </Col>
          </>
        )}
              </Row>
            </Col>
          </Row>
         </Fragment>
      </div>
      {showModal && fetchOrdersModal()}
    </div>
  );
};

SubORdersForLh.propTypes = {
  accounts: []
};

SubORdersForLh.defaultProps = {
  accounts: [],
  fetchData: () => {},
};

export default withRouter(SubORdersForLh);
